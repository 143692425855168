import React, { FC } from "react";

import Header from "./Header";
import ContentWrapper from "./ContentWrapper";
import styles from "./styles.module.scss"

const LayoutPresentation: FC = ({ children }) => {
  return (
    <div className={styles.horizontalNav}>
      <div className={styles.navSection}>
        <Header />
      </div>
      <div className={styles.pageSection}>
        <ContentWrapper>{children}</ContentWrapper>
      </div>
    </div>
  );
};

export default LayoutPresentation;
