import * as base from '../actions/lib/baseActions'
import { apiEndpoints } from "lib/config";
import {  AuthResult, CreateEditUser, LoginUserData, LoginUserResult, ResetPassword, ResetPasswordEmail, UpdateUserData, User } from "types";

export const fetchUser = async () => {
  const response = await base.get(apiEndpoints.usersMe);
  return response.data;
};

export const loginUser = async (data: LoginUserData): Promise<AuthResult> => {  const { email, password, captchaToken } = data;
   const response = await base.post(apiEndpoints.login, { email, password, captchaToken });
   return response.data;
};


export const createFullUser = async (data: CreateEditUser) => {
  const response = await base.post(apiEndpoints.users, data);
  return response.data;
};

export const updateUser = async (data: UpdateUserData) => {
  const response = await base.put(`${apiEndpoints.users}/${data.id}`, data);
  return response.data;
};

export const resetPassword = async (data: ResetPassword) => {
  const response = await base.put(apiEndpoints.userPasswordReset, data);
  return response.data;
};

export const resetPasswordEmail = async (data: ResetPasswordEmail) => {
  const response = await base.get(apiEndpoints.userPasswordResetEmail, {params: data});
  return response.data;
};

export const changePassword = async (data: ResetPassword) => {
  const response = await base.put(apiEndpoints.userPasswordChange, data);
  return response.data;
};

export const fetchAllUsers = async () => {
  const response = await base.get(apiEndpoints.usersAll);
  return response.data;
};

export const deleteUser = async (id: number) => {
  const response = await base.del(`${apiEndpoints.users}/${id}`);
  return response.data;
};
