import * as base from "../actions/lib/baseActions";
import { apiEndpoints } from "lib/config";
import { CreateImpactsData, GetImpactsParams, Impact  } from "types";


export const fetchImpacts = async (data: GetImpactsParams): Promise<Array<Impact>> => {
  const response = await base.get(apiEndpoints.impacts, 
    {params: data});
  return response.data;
};

export const fetchImpact = async (data: GetImpactsParams ): Promise<Impact> => {
  const response = await base.get(`${apiEndpoints.impacts}impact`, 
    {params: data});
  return response.data;
};

export const fetchTotalImpactsCount = async (data: GetImpactsParams): Promise<number> => {
  const response = await base.get(`${apiEndpoints.impacts}count`,
    {params: data});
  
  return response.data;
};
