import * as base from "../actions/lib/baseActions";
import { apiEndpoints } from "lib/config";
import {CreateOrder, getOrdersFilters,  } from "types";
import { formatISODateOnly } from "lib/commonFunctions";

 
export const getOrders = async (filters: getOrdersFilters): Promise<Array<any>> => {
  const response = await base.get(`${apiEndpoints.orders}`,
    {params: {
        minDate: filters.minDate? formatISODateOnly(filters.minDate): undefined,
        maxDate: filters.maxDate? formatISODateOnly(filters.maxDate): undefined,
        clientId: filters.clientId,
      }
    }
  );
  return response.data;
};

export const getPlasticsPrice = async (): Promise<any> => {
  const response = await base.get(`${apiEndpoints.orders}/plasticsPrice`);
  return response.data.pricePerGram;
};

export const updatePlasticsPrice = async (price: number): Promise<any> => {
  const response = await base.put(`${apiEndpoints.orders}/plasticsPrice`, {
    price
  });
  return response.data;
};

export const createOrder = async (data: CreateOrder) => {
  const response = await base.post(apiEndpoints.orders, data);
  return response.data;
};


   