import classNames from "classnames";
import BaseModal from "components/BaseModal";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

interface ConfirmationModalProps {
  open: boolean;
  onConfirm: () => void;
  onDecline: () => void;
  message?: string;
  title?: string;
}
function ConfirmationModal({
  open,
  onConfirm,
  onDecline,
  message,
  title,
}: ConfirmationModalProps): ReactElement {
  return (
    <>
      {open && (
        <BaseModal>
          <div className={styles.modal}>
            <div className={styles.title}>{title}</div>
            <div className={styles.message}>{message}</div>
            <div className={styles.confirmationOptions}>
              <div className={styles.cancelButton}>
                <button
                  className={classNames(styles.button, styles.baseButton)}
                  onClick={onDecline}
                >
                  Cancel
                </button>
              </div>
              <div className={styles.confirmButton}>
                <button
                  className={classNames(styles.button, styles.baseButton)}
                  onClick={onConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </BaseModal>
      )}
    </>
  );
}

export default ConfirmationModal;
