import React, {
  useState,
  useEffect,
  SetStateAction,
  ReactElement,
} from "react";
import SearchBar from "components/SearchBar";
import styles from "./styles.module.scss";
import { Client, ClientEmail, CreateEditClient } from "types";
import CreateEditClientModal from "../CreateEditClientModal/CreateEditClientModal";
import useClientsHook from "hooks/useClientsHook";
import AlertModal from "components/AlertModal";
import useDebounceHook from "hooks/useDebounceHook";
import ConfirmationModal from "components/ConfirmationModal";
import ClientAutocomplete from "components/ClientAutocomplete";
import classNames from "classnames";
import Pagination from "components/pagination";
import { Button } from "components/Buttons";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import {BsCheck} from "@react-icons/all-files/bs/BsCheck";
import { IconContext } from "@react-icons/all-files";
import BaseModal from "components/BaseModal";

function ClientForm(): ReactElement {
  const [openCreateEditClient, setOpenCreateEditClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [selectedClientForDelete, setSelectedClientForDelete] = useState<Client>();
  const [selectedClientSearch, setSelectedClientSearch] = useState<Client>();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const postsPerPage = 16;
  const maxPageTabs = 7;

  const {
    clients,
    fetchClients,
    createClient,
    deleteClient,
    updateClient,
    resetClientError,
    setSearchValue,
    totalCount,
    fetchTotalClientCount,
    clientErrorMessage,
    searchValue,
  } = useClientsHook();

  const searchDebounce = useDebounceHook(searchValue);

  const tableColumns = ["Client Name", "Client Email", "Pantree", "Food Service", "", ""];

  const onDelete = async (client: Client) => {
    setSelectedClientForDelete(client);
    setDeleting(true);
  };

  const onDecline = () => {
    setDeleting(false);
    setSelectedClientForDelete(undefined);
  };

  const onCreateClient = async (values: CreateEditClient) => {
    await createClient(values);
    fetchClients({limit: postsPerPage, searchFilter: searchDebounce})
  }

  const onUpdateClient = async (values: CreateEditClient) => {
    await updateClient(values);
    fetchClients({from: (currentPage - 1) * postsPerPage, limit: postsPerPage, searchFilter: searchDebounce})
  }

  const onConfirm = async() => {
    setDeleting(false);
    if (selectedClientForDelete?.id) {
      await deleteClient(selectedClientForDelete.id);
      fetchClients({limit: postsPerPage, searchFilter: searchDebounce})
    }
    setSelectedClientForDelete(undefined);
  };

  const toggleCreateClient = () => {
    setOpenCreateEditClient((prev) => !prev);
  };

  const onEditCreateClose = () => {
    setSelectedClient(undefined);
    setOpenCreateEditClient(false);
  };

  useEffect(() => {
    const startingRow = (currentPage - 1) * postsPerPage;
    fetchClients({
      limit: postsPerPage,
      from: startingRow,
      searchFilter: searchDebounce,
    });
  }, [currentPage]);

  useEffect(() => {
    fetchTotalClientCount();
  }, []);

  useEffect(() => {
    setOpenCreateEditClient(!!selectedClient);
  }, [selectedClient]);

  useEffect(() => {
    fetchTotalClientCount(searchDebounce);
    setSelectedClient(undefined);
    fetchClients({ limit: postsPerPage, searchFilter: searchDebounce });
    setCurrentPage(1);
  }, [searchDebounce]);

  return (
    <div>
      <div>
        <div className={styles.tableFilters}>
          <Button
            onClick={toggleCreateClient}
            className={classNames(styles.baseButton, styles.createClientButton)}
          >
            Create Client
          </Button>
          <div className={styles.searchBar}>
            <ClientAutocomplete
              updateSelectedClient={setSelectedClientSearch}
              clientList={clients}
              setValue={setSearchValue}
              value={searchValue}
            />
          </div>
        </div>
        <div>
          <table className={styles.table}>
            <thead>
              <tr key="HeaderRow">
                {tableColumns.map((col: string, index) => {
                  return <th key={index}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {clients.map((client: Client, index: number) => {
                return (
                  index < postsPerPage && (
                    <tr key={client.id} className={styles.striped}>
                      <td>{client.clientName}</td>
                      <td>{client.emails.map((email: ClientEmail) => {return (`${email.email} `)})}</td>
                      <td className={styles.iconDiv}>
                        {client.emails.some((email: ClientEmail) => email.pantreeId) ? <BsCheck/> : <></>}
                      </td>
                      <td className={styles.iconDiv}>
                        {client.emails.some((email: ClientEmail) => email.foodServiceId) ? <BsCheck/> : <></>}
                      </td>

                      <td className={styles.iconDiv}>
                        <button
                          onClick={() => setSelectedClient(client)}
                          className={styles.tableButton}
                        >
                          <IconContext.Provider value={{ size: "2em" }}>
                            <FaEdit />
                          </IconContext.Provider>
                        </button>
                      </td>
                      <td className={styles.iconDiv}>
                        <button
                          onClick={() => onDelete(client)}
                          className={styles.tableButton}
                        >
                          <IconContext.Provider value={{ size: "2em" }}>
                            <AiOutlineDelete />
                          </IconContext.Provider>
                        </button>
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
          <div className={styles.incrementDiv}>
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={totalCount}
              changePage={setCurrentPage}
              maxPageTabs={maxPageTabs}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
     
     
      <CreateEditClientModal
        open={!!openCreateEditClient}
        onCreateClient={onCreateClient}
        onClose={onEditCreateClose}
        onUpdate={onUpdateClient}
        client={selectedClient}
      />
       <ConfirmationModal
        open={!!deleting}
        onConfirm={onConfirm}
        onDecline={onDecline}
        title={"Deleting Client"}
        message={"Are you sure you wish to delete this client?"}
      />
       <AlertModal
        open={!!clientErrorMessage}
        onClose={resetClientError}
        title={"Error"}
        message={clientErrorMessage}
      />
    </div>
  );
}

export default ClientForm;
