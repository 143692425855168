import styles from './styles.module.scss';

interface QuantitySelectParams {
    updateSelectedQuantity: (quanitySelected:number) => void;
    allowNegatives?: boolean;
    value?: number;
}

function QuantitySelect({updateSelectedQuantity, allowNegatives = false, value}: QuantitySelectParams) {

  return (
    <form>
      <input
        type="number"
        className={styles.quantity}
        min={!allowNegatives ? 0 : undefined}
        onChange={(e) => updateSelectedQuantity(e.target.valueAsNumber)}
        value={value || ''}
      />
    </form>
  );
};
export default QuantitySelect;