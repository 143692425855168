import * as base from "../actions/lib/baseActions";
import { apiEndpoints } from "lib/config";
import {CreateCreditMemo, CreateOrder, CreditMemo, getOrdersFilters,  } from "types";
import { formatISODateOnly } from "lib/commonFunctions";

 
export const fetchCreditMemos = async (clientId: number, siteId: number): Promise<Array<CreditMemo>> => {
  const response = await base.get(`${apiEndpoints.creditMemos}`,{
    params: { clientId, siteId }
  }
  );
  return response.data;
};

export const create = async (data: CreateCreditMemo) => {
  const response = await base.post(apiEndpoints.creditMemos, data);
  return response.data;
};

export const deleteMemo = async (id: number) => {
  const response = await base.del(`${apiEndpoints.creditMemos}/${id}`);
  return response.data;
};


   