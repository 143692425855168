import classNames from 'classnames';
import CalendarRange from 'components/CalendarRange';
import { addMonthsFromCurrentDate, firstDayOfTheYear, todaysDate } from 'lib/commonFunctions';
import React, { ReactElement, useState } from 'react'
import styles from './MultiplePresetDatePicker.module.scss';

interface MultiplePresetDatePickerProps {
  minDate?: Date;
  maxDate?: Date;
  setMinDate: (date: Date) => void; 
  setMaxDate: (date: Date) => void; 
}

enum BUTTON_VALUES {
  Total = 0,
  PastMonth = 1,
  PastThreeMonths = 2,
  PastYear = 3,
  YearToDate = 4,
}

function MultiplePresetDatePicker({minDate, maxDate, setMaxDate, setMinDate}: MultiplePresetDatePickerProps): ReactElement {

  const [selectFilter, setSelectedFilter] = useState<BUTTON_VALUES>(BUTTON_VALUES.Total);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState<boolean>(false);

  const buttonNames = [
    {
      id: 0,
      buttonText: "Total",
      min: undefined,
      max: undefined,
    },
    {
      id: 1,
      buttonText: "Past Month",
      min: addMonthsFromCurrentDate(-1),
      max: todaysDate,
    },
    {
      id: 2,
      buttonText: "Past 3 Months",
      min: addMonthsFromCurrentDate(-3),
      max: todaysDate,
    },
    {
      id: 3,
      buttonText: "Past Year",
      min: addMonthsFromCurrentDate(-12),
      max: todaysDate,
    },
    {
      id: 4,
      buttonText: "Year To Date",
      min: firstDayOfTheYear(),
      max: todaysDate,
    },
  ];

  const clickDateButton = (buttonValue: any) => {

    setSelectedFilter(buttonValue.id)
      setMinDate(buttonValue.min);
      setMaxDate(buttonValue.max);

  };

  return <div>
    <div className={styles.timeButtons}>
        {buttonNames.map((buttonValue) => {
          return (
            <button
              onClick={() => clickDateButton(buttonValue)}
              key={buttonValue.buttonText}
              className={classNames(
                styles.baseButton,
                styles.impactTimeButtons,
                selectFilter == buttonValue.id && styles.filterSelected,
              )}
            >
              {buttonValue.buttonText}
            </button>
          );
        })}
      </div>
      <div className={styles.datePickDiv}>
        
          <div className={styles.datePickDiv}>
            <CalendarRange
              minDate={minDate}
              maxDate={maxDate}
              updateMin={setMinDate}
              updateMax={setMaxDate}
            />
          </div>

      </div>
  
  </div>
}

export default MultiplePresetDatePicker;