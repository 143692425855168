import React, { ReactElement } from "react";
import Layout from "Layout";
import ImpactsForm from "./ImpactsForm";
import styles from "./styles.module.scss";


function Impacts(): ReactElement {
  return (
    <Layout>
      <div className={styles.impactsPage}>
        <h1 className={styles.elegantShadow}>Impacts</h1>
        <div className={styles.barContainer}>
          <div className={styles.bar} />
        </div>
        <ImpactsForm />
      </div>
    </Layout>
  );
};

export default Impacts