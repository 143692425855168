import * as base from "../actions/lib/baseActions";
import { apiEndpoints } from "lib/config";
import { ShopifyClient, ShopifyProduct } from "types";

export const findClient = async (email: string): Promise<ShopifyClient> => {
  const response = await base.get(`${apiEndpoints.shopify}/findClient`, {params:{email}});
  return response.data;
};

export const findProduct = async (productId: number): Promise<ShopifyProduct> => {
  const response = await base.get(`${apiEndpoints.shopify}/findProduct`, {params:{productId}});
  return response.data;
};

