import Layout from "Layout";
import LoginForm from "./LoginForm";
import { ReactElement } from "react";
import styles from "./styles.module.scss";

function Login(): ReactElement {

  return (
    <Layout>
      <div className={styles.loginPage}>
        <h1>Hello, and welcome to GoJava </h1>
        <div className={styles.barContainer}>
          <div className={styles.bar} />
        </div>
        <div className={styles.loginOptions}>
          <p>Please Login</p>
          <LoginForm />
        </div>
      </div>
    </Layout>
  );
};

export default Login;
