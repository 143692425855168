import { formatOneDecimal } from "./commonFunctions"

export const filler = [] // Remove when new objects are added

export const startTrackingDate = '2017-09-01';
const kilosPerGarbageCan = 15;
const podsPerKilo = 44.092;
const podsPerCNTower = 12433

export const getStartTrackingDate = () => {
  const date = new Date();
  date.setFullYear(2017);
  date.setMonth(8);
  date.setDate(1)
  return date;
}

export const getWasteEquivalences = (kilos?: number) => {
  if (!kilos) return 0
  return formatOneDecimal(kilos / kilosPerGarbageCan)
}

export const getPodEquivalences = (kilos?: number) => {
  if (!kilos) return 0
  return formatOneDecimal(kilos * podsPerKilo / podsPerCNTower)
}
