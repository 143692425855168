import React, { FC } from "react";
import classNames from "classnames";
import styles from './styles.module.scss';

export interface IImageProps {
    className?: string;
}

export const PanTreeLogo: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "logo--pantree")} 
    />
  );
}

export const CnTower: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--cntower")} 
    />
  );
}

export const CoffeePods: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--coffeepods")} 
    />
  );
}

export const ElectricWaste: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--ewaste")} 
    />
  );
}

export const FoilPackaging: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--foilpackaging")} 
    />
  );
}

export const OrganicWaste: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--organicwaste")} 
    />
  );
}

export const OtherWaste: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--otherwaste")} 
    />
  );
}

export const PaperCups: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--papercups")} 
    />
  );
}

export const Trees: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--trees")} 
    />
  );
}

export const Waste: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--waste")} 
    />
  );
}

export const WasteBin: FC<IImageProps> = ({
  className = ""
}) => {
  return (
    <div 
    className={classNames(className, styles.images, "i--impact--wastebin")} 
    />
  );
}

