import React, { ReactElement } from 'react';
import { CSVLink,  } from "react-csv";
import { CSVHeader } from 'types';
import styles from "./styles.module.scss";




interface CSVDownloadProps {
  values: Array<any>;
  headers: Array<CSVHeader>;
  title?: string;
  filename?: string;
}


function CSVDownload({values, headers, title = 'Download', filename = 'download.csv'}: CSVDownloadProps): ReactElement {

  return (
    <CSVLink data={values} headers={headers} filename={filename}>
      <div className={styles.downloadButton}> 
        {title}
      </div>
    </CSVLink>
  )
}

export default CSVDownload;