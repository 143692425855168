import { ReactElement, useEffect, useState } from "react";
import { Formik, Form, FormikHelpers, useFormikContext } from "formik";
import { Button } from "components/Buttons";
import { TextField } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import { CreateCreditMemo } from "types";
import BaseModal from "components/BaseModal";
import { todaysDateString } from "lib/commonFunctions";
import useCreditMemoHook from "hooks/useCreditMemoHook";

interface CreateCreditMemoModalProp {
  siteId: number;
  open: boolean;
  onClose: () => void;
  clientId?: number;
  onSave: () => void;
}

function CreateCreditMemoModal({
  siteId,
  open,
  clientId,
  onClose,
  onSave
}: CreateCreditMemoModalProp): ReactElement {

  const [signUpError, setCreateEditUserError] = useState<string | null>(null);
  const [values, setValues] = useState<CreateCreditMemo>({});

  const {create} = useCreditMemoHook();

  const onLocalClose = () => {
    setValues({})
    onClose();
  }

  const onCreate = async (
    values: CreateCreditMemo,
  ) => {
    if (!values.amount) return;
    await create({siteId: values.siteId, amount: Math.abs(values.amount || 0), clientId: clientId, date: todaysDateString(), notes: values.notes});
    onSave();
  }

  useEffect(() => {
    setValues(prev => {
      return {
        ...prev,
        siteId
      }
    })
  }, [siteId])


  return (
    <>
      {open && (
        <BaseModal>
          <Formik
            initialValues={values}
            onSubmit={onCreate}
            enableReinitialize={true}
          >
            {({ isSubmitting, errors }) => (
              <Form className={styles.modal}>
                <div className={styles.title}>Create Credit Memo</div>
                <TextField
                  type="number"
                  name="amount"
                  label="Amount ($)"
                  className={styles.field}
                  labelClassName={ styles.labels}
          
                />
                <TextField
                  type="text"
                  name="notes"
                  label="Notes"
                  className={styles.field}
                  labelClassName={ styles.labels}
                  textFieldStyles={styles.textField}
                />
                <div className={styles.buttonSection}>
                  <Button
                    className={styles.closeButton}
                    type="reset"
                    onClick={onLocalClose}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    className={styles.submitButton}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
                {signUpError && <div>{signUpError}</div>}
              </Form>
            )}
          </Formik>
        </BaseModal>
      )}
    </>
  );
}

export default CreateCreditMemoModal;
