import React, { FC } from "react";
import classNames from "classnames";
import { Field, ErrorMessage } from "formik";

import styles from "./styles.module.scss";
import { TextFieldProps } from "types";



const TextArea = (props: any) => {
  return <textarea {...props} />;
};

export const TextField: FC<TextFieldProps> = ({
  name,
  label,
  required,
  type,
  placeholder,
  className,
  labelClassName,
  textFieldStyles,
  disabled
}) => {
  return (
    <div className={className}>
      {(label || required) && (
        <label
          htmlFor={name}
          className={classNames(styles.label, labelClassName)}
        >
          {label}
          {required && <span className={styles.required}>&emsp;</span>}
        </label>
      )}
      {type === "textArea" ? (
        <Field
          as={TextArea}
          name={name}
          placeholder={placeholder}
          className={classNames(styles.field, textFieldStyles)}
          disabled={disabled}
        />
      ) : (
        <Field
          type={type}
          name={name}
          placeholder={placeholder}
          className={classNames(styles.field, textFieldStyles)}
          disabled={disabled}
        />
      )}
      <div>
        <ErrorMessage name={name} component="div" className={styles.alert} />
      </div>
    </div>
  );
};

export default TextField;
