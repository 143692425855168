import { TreeProductsApi } from "api";
import { getAPIErrorMessage } from "lib/errorHandling";
import React, { useState } from "react";
import { CreateTreeProduct, TreeProduct } from "types";

interface TreeProductHookInfo {
  fetchTreeProducts: () => void;
  createTreeProduct: (newProduct: CreateTreeProduct) => void;
  updateTreeProduct: (updatedProduct: TreeProduct) => void;
  resetTreeProductError: () => void;
  treeProducts: Array<TreeProduct>;
  treeProductErrorMessage: string;
}

function useTreeProductHook(): TreeProductHookInfo {
  const [treeProducts, setTreeProducts] = useState<Array<TreeProduct>>([]);
  const [treeProductErrorMessage, setTreeProductErrorMessage] = useState<string>("");

  const setError = (err: any) => {
    const message = getAPIErrorMessage(err);
    setTreeProductErrorMessage(message);
  };

  const fetchTreeProducts = async () => {
    try {
      const treeProductsData = await TreeProductsApi.fetchTreeProducts();
      setTreeProducts(treeProductsData);
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const createTreeProduct = async (data: CreateTreeProduct) => {
    try {
      await TreeProductsApi.createTreeProduct(data);
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const updateTreeProduct = async (data: TreeProduct) => {

    try {
      await TreeProductsApi.updateTreeProduct(data);
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const resetTreeProductError = () => {
    setTreeProductErrorMessage('')
  }

  return {
    fetchTreeProducts,
    createTreeProduct,
    updateTreeProduct,
    resetTreeProductError,
    treeProducts,
    treeProductErrorMessage
  };
}

export default useTreeProductHook;
