import React, { FC } from "react";
import { AppContextProvider } from "./app";
import { UserContextProvider } from "./user";

export { useUserState, useSetUserState, useMergeUserState } from "./user";
export { useAppState, useSetAppState, useMergeAppState } from "./app";



export const AppProvider: FC = ({ children }) => {
  return (
    <AppContextProvider>
      <UserContextProvider>{children}</UserContextProvider>
    </AppContextProvider>
  );
};
