import TreeProducts from "pages/TreeProducts";

export const baseAPIUrl = process.env.REACT_APP_API_URL;
export const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

export const PANTREE_STIE_ID = 0;
export const FOOD_SERVICE_SITE_ID = 1;


// Jwt specific data
export const jwtData = {
  refreshUrl: "/users/refreshJwt",
  authToken: "authToken",
  refreshToken: "refreshToken",
};

export const apiEndpoints = {
  login: "login",
  users: "users",
  userPasswordReset: "users/resetPassword",
  userPasswordResetEmail: "users/resetPasswordEmail",
  userPasswordChange: "users/changePassword",
  usersMe: "users/me",
  usersAll: "users/all",
  client: "clients",
  orders: "orders",
  creditMemos: "creditMemos",
  treeProducts: "treeProducts",
  shopify: "shopify",
  deleteUserByEmail: "users/deleteUserByEmail",
  deleteClientByEmail: "client/deleteClientByEmail",
  impacts: "impacts/",
  healthCheckApi: "healthCheck/api",
  healthCheckDatabase: "healthCheck/database",
};

export const typingDebounce = 300;