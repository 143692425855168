import React, { ReactElement } from 'react';
import styles from './styles.module.scss';
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import { IconContext } from "@react-icons/all-files";
import classNames from 'classnames';
import { DateFormat, formatDate } from 'lib/commonFunctions';


interface RowInfoProps {
  orderNumber?: number;
  amount: number;
  creditMemo: boolean;
  date: string;
  notes?: string;
  onDeleteMemo: () => void;
}

function RowInfo({orderNumber, amount, creditMemo, notes, date, onDeleteMemo}: RowInfoProps): ReactElement {

  return (
    <tr className={classNames(creditMemo ? styles.creditMemo : styles.order, styles.container)}>
      <td className={styles.date}>{formatDate(date, DateFormat.DATE_TIME_SHORT)}</td>
      <td className={styles.orderNumber}>{creditMemo ? '' : orderNumber}</td>
      <td>{creditMemo ? notes : ''}</td>
      <td className={classNames(creditMemo ? styles.creditMemo : styles.order, styles.amount)}>{`$${amount.toFixed(2)}`}</td>
      <td className={styles.deleteBtn}>
        {creditMemo && (
          <IconContext.Provider value={{ size: "1.5em" }}>
            <AiOutlineDelete onClick={onDeleteMemo}/>
          </IconContext.Provider>
        )}
      </td>
      
    </tr>
  )
}

export default RowInfo;