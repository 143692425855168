import React, { FC, ReactElement, useState } from "react";
import styles from "./styles.module.scss";
import CalendarRange from "components/CalendarRange";
import classNames from "classnames";

function ClientReportsForm(): ReactElement {
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();

  return (
    <div className={styles.clientReportFields}>
      <div className={styles.datePickDiv}>
        <CalendarRange
          minDate={minDate}
          maxDate={maxDate}
          updateMin={setMinDate}
          updateMax={setMaxDate}
        />
      </div>
      <div className={styles.barContainer}>
        <div className={styles.bar} />
      </div>
      <div className={styles.reportButtons}>
        <div>
          <button
            className={classNames(
              styles.baseButton,
              styles.reportActionButtons
            )}
          >
            Create Report
          </button>
        </div>
        <div>
          <button
            className={classNames(
              styles.baseButton,
              styles.reportActionButtons
            )}
          >
            Create Report
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClientReportsForm;
