import React, { FC, useState, createContext, useContext } from "react";

// This is for global context state.
// This is a simple context store, that creates a context provider, to wrap the parent.
// You can then get the state of the created store to get the context state.
// You can also use the setState to update the base store, anywhere in the app.
// If this context is not sufficient, and there are too many contexts, consider using redux
export const creatContextStore = <T,>(
  initialState: T
): [
  React.FunctionComponent<{}>,
  () => T,
  () => React.Dispatch<React.SetStateAction<T>>,
  () => (val: T) => void
] => {
  const stateContext = createContext<T>(initialState);
  const setStateContext = createContext<
    React.Dispatch<React.SetStateAction<T>>
  >(() => {});

  const Provider: FC = ({ children }) => {
    const [state, setState] = useState(initialState);

    return (
      <stateContext.Provider value={state}>
        <setStateContext.Provider value={setState}>
          {children}
        </setStateContext.Provider>
      </stateContext.Provider>
    );
  };

  return [
    Provider,
    () => {
      return useContext(stateContext);
    },
    () => {
      return useContext(setStateContext);
    },
    () => {
      const s = useContext(stateContext);
      const setS = useContext(setStateContext);

      return (val: T) => {
        setS({ ...s, ...val });
      };
    },
  ];
};
