import * as base from "../actions/lib/baseActions";
import { apiEndpoints } from "lib/config";
import {Client,  CreateEditClient,  } from "types";

export const fetchClients = async ({searchFilter, from, limit, siteId}: any) => {
  const response = await base.get(apiEndpoints.client, {params:{searchFilter, from, limit, siteId}});
  return response.data;
};

export const fetchTotalClientCount = async (searchFilter?: string): Promise<number> => {
  const response = await base.get(`${apiEndpoints.client}/count`,
    {params: {searchFilter}});
  return response.data;
};

export const fetchClient = async (id: number): Promise<Client> => {
  const response = await base.get(`${apiEndpoints.client}/${id}`);
  return response.data;
}


export const deleteClient = async (id: number) => {
  const response = await base.del(`${apiEndpoints.client}/${id}`);
  return response.data;
};

export const createClient = async (data:   CreateEditClient) => {
  const response = await base.post(apiEndpoints.client, data);
  return response.data;
};

export const updateClient = async (data:   CreateEditClient) => {
  const response = await base.put(`${apiEndpoints.client}/${data.id}`, data);
  return response.data;
};
   