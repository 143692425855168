import { useState } from "react";
import DatePicker from "react-datepicker";
import { BsCalendar } from "@react-icons/all-files/bs/BsCalendar";

import styles from "./styles.module.scss";
import { todaysDate } from "lib/commonFunctions";
import { getStartTrackingDate, startTrackingDate } from 'lib/impact'



interface CalendarRangeParams {
  minDate: Date | undefined
  maxDate: Date | undefined
  updateMin: (min:Date) => void;
  updateMax: (max:Date) => void;
  disabled?: boolean

}


function CalendarRange({minDate, maxDate, disabled,  updateMin, updateMax}: CalendarRangeParams) {

  const trackingMinDate = getStartTrackingDate();
  return (
    <>
        <BsCalendar/>
        <DatePicker
          openToDate={minDate? minDate: todaysDate}
          selected={minDate}
          className={styles.datePick}
          placeholderText= "Choose a start date"  
          onChange={(date: Date) => updateMin(date)}
          dateFormat='dd/MM/yyyy'
          maxDate={maxDate}
          minDate={trackingMinDate}
          disabled={disabled}
          showYearDropdown
          scrollableMonthYearDropdown />
        <BsCalendar/>
        <DatePicker
          openToDate={maxDate? maxDate: todaysDate}
          selected={maxDate}
          className={styles.datePick}
          placeholderText="Choose an end date" 
          onChange={(date: Date) => updateMax(date)}
          dateFormat='dd/MM/yyyy'
          minDate={minDate || trackingMinDate}
          disabled={disabled}
          showYearDropdown
          scrollableMonthYearDropdown />
    </>
  );
};
export default CalendarRange;