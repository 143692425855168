import classNames from 'classnames';
import AlertModal from 'components/AlertModal';
import BaseModal from 'components/BaseModal';
import { Button } from 'components/Buttons';
import Modal from 'components/Modal';
import useOrdersHook from 'hooks/useOrdersHook';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './PlasticsSettings.module.scss';

interface PlasticsSettingsProps {
  open: boolean;
  onClose: () => void;

}
function PlasticsSettings({open, onClose}: PlasticsSettingsProps): ReactElement {

  const [newPlasticsPrice, setNewPlasticsPrice] = useState<number>()
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false)

  const {
    getPlasticsPrice,
    updatePlasticsPrice,
    plasticsPrice,
  } = useOrdersHook();

  const onSave = async () => {
    try {
      if (!newPlasticsPrice) return;
      await updatePlasticsPrice(newPlasticsPrice)
      setOpenAlertModal(true)
     
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!open) return;
    getPlasticsPrice();
  }, [open])

  return (
    <>
      {open && (
        <BaseModal>
          <div className={styles.modal}>
            <div className={styles.title}>{'Plasitc Settings'}</div>
            <div className={styles.plastics}>
              <label className={styles.label}>Current: </label>
              <div>{`$${plasticsPrice}`}</div>
            </div>
            <div className={styles.message}>
              <label className={styles.label}>{'Price ($/g):'}</label>
              <input type={'number'} step={0.001} onChange={({target}) => setNewPlasticsPrice(target.value ? +target.value : undefined)}/>
            </div>
            
            <div className={styles.button}>
              <Button
                onClick={onClose}
                className={classNames(styles.orderButton, styles.baseButton)}
              >
                Close
              </Button>
              <Button
                onClick={onSave}
                className={classNames(styles.orderButton, styles.baseButton)}
              >
                Save
              </Button>
            </div>
          </div>
          <AlertModal 
            open={openAlertModal}
            title={'Plastic Price Changed'}
            message={`$${newPlasticsPrice} per gram`}
            onClose={() => {
              setOpenAlertModal(false)
              onClose()
            }}
          />

        </BaseModal>
      )}
    </>
    
  )
}

export default PlasticsSettings;