import { IconContext } from "@react-icons/all-files";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import classNames from "classnames";
import AlertModal from "components/AlertModal";
import { Button } from "components/Buttons";
import useTreeProductHook from "hooks/useTreeProductsHook";
import { formatDate, formatISODateOnly } from "lib/commonFunctions";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { TreeProduct } from "types";
import CreateTreeProductModal from "../CreateTreeProductModal";
import styles from "./styles.module.scss";

function TreeProductsForm(): ReactElement {
  const [selectedTreeProduct, setSelectedTreeProduct] = useState<TreeProduct>();
  const [treeModalOpen, setTreeModalOpen] = useState<boolean>(false);
  const [alertModalOpen, setAlertModalOpen] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>();
  const [alertMessage, setAlertMessage] = useState<string>();

  const {
    fetchTreeProducts,
    createTreeProduct,
    updateTreeProduct,
    treeProducts,
    treeProductErrorMessage
  } = useTreeProductHook();


  useEffect(() => {
    fetchTreeProducts();
  }, []);

  useEffect(() => {
    setAlertTitle("Error")
    setAlertMessage(treeProductErrorMessage);
  }, [treeProductErrorMessage]);

  

  const tableColumns = ["Product Name", "Shopify Id", "Trees Per Quantity", ""];

  const editProduct = (product: TreeProduct) => {
    setSelectedTreeProduct(product);
    setTreeModalOpen(true);
  };

  const onClose = () => {
    setAlertModalOpen(false)
  }

  const openCreateModal = () => {
    setTreeModalOpen(true);
  };

  const closeCreateModal = () => {
    setTreeModalOpen(false);
    setSelectedTreeProduct(undefined);
  };

  const onUpdateCreateTreeProduct = () => {
    fetchTreeProducts();
  }

  return (
    <div>
      <div className={styles.tableFilters}>
        <Button onClick={openCreateModal} className={styles.baseButton}>
          Create Tree Product
        </Button>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            {tableColumns.map((col: string) => {
              return <th key={col}>{col}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {treeProducts.map((product: TreeProduct, index: number) => {
            return (
              <tr key={product.id} className={styles.striped}>
                <td className={styles.tableColumn}>{product.name}</td>
                <td className={styles.tableColumn}>
                  {product.shopifyProductId}
                </td>
                <td className={styles.productDateStatus}>
                  {product.treesPerQuantity}
                </td>
                <td className={styles.iconDiv}>
                  <button
                    onClick={() => editProduct(product)}
                    className={styles.impactButton}
                  >
                    <IconContext.Provider value={{ size: "2em" }}>
                      <FaEdit />
                    </IconContext.Provider>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {treeModalOpen && (
          <CreateTreeProductModal
            treeProduct={selectedTreeProduct}
            onClose={closeCreateModal}
            onSave={onUpdateCreateTreeProduct}
          />
        )}
        <AlertModal
          open={alertModalOpen}
          onClose={onClose}
          title={alertTitle}
          message={alertMessage}
        />
    </div>
  );
}

export default TreeProductsForm;
