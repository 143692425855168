import { UserInfo } from "types";
import { creatContextStore } from "./base";

const initialState = {
  email: undefined,
  firstName: "",
  lastName: "",
};

const [Provider, useState, useSetState, useMergeState] =
  creatContextStore<UserInfo>(initialState);

export {
  Provider as UserContextProvider,
  useState as useUserState,
  useSetState as useSetUserState,
  useMergeState as useMergeUserState,
};
