import React, { ReactElement } from 'react';
import styles from './styles.module.scss'



interface ToggleProps {
  buttonOneLabel: string;
  buttonTwoLabel: string;
  onButtonOneClick?: () => void;
  onButtonTwoClick?: () => void;
}
function Toggle({buttonOneLabel, buttonTwoLabel, onButtonOneClick, onButtonTwoClick}: ToggleProps): ReactElement {

  const handleChange = (e: any) => {
    if (e.target.checked) {
      if (onButtonTwoClick) onButtonTwoClick()
    } else {
      if (onButtonOneClick) onButtonOneClick()
    }
  }
  return (
    <div className={styles.container}>
      {buttonOneLabel}{" "}
      <div className={styles.toggleSwitch}>
        <input type="checkbox" className={styles.checkbox} 
               name={buttonOneLabel} id={buttonOneLabel} onChange={(e) => handleChange(e)}/>
        <label className={styles.label} htmlFor={buttonOneLabel}>
          <span className={styles.inner} />
          <span className={styles.switch} />
        </label>
      </div>
      {" "}{buttonTwoLabel}
    </div>

  )
}

export default Toggle;