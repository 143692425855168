import React, { useState } from 'react'
import {ClientsApi} from 'api'
import {Client, CreateEditClient } from 'types';
import { getAPIErrorMessage } from 'lib/errorHandling';


interface ClientHookInfo {
  clientLoading: boolean;
  clients: Array<Client>;
  clientErrorMessage: string;
  searchValue: string;
  totalCount: number;
  client: Client | undefined;
  fetchClient: (id: number) => void;
  fetchTotalClientCount: (searchFilter?: string) => void;
  setSearchValue: (value: string) => void;
  fetchClients: ({searchFilter, from, limit, siteId}: any) => void;
  createClient: (client:CreateEditClient) => void;
  deleteClient: (id: number) => void;
  updateClient: (client: CreateEditClient) => void;
  resetClientError: () => void;
  clearClient: () => void;
  clearClients: () => void;
}

function useClientsHook(): ClientHookInfo {
  
  const [clients, setClients] = useState<Array<Client>>([])
  const [client, setClient] = useState<Client>();
  const [loading, setLoading] = useState<boolean>(false)
  const [clientErrorMessage, setClientErrorMessage] = useState<string>('');
  const [searchValue, setSearchValue] = useState("");
  const [totalCount, setTotalCount] = useState<number>(0)

  const setError = (err: any) => {
    const message = getAPIErrorMessage(err);
    setClientErrorMessage(message)
  }

  const fetchClients = async ({from, limit, searchFilter, siteId}:any) => {
    try {
      const clients = await ClientsApi.fetchClients({searchFilter, from, limit, siteId});
      setClients(clients);
    } catch (err) {
      setError(err)
      throw err;
    }
  }

  const fetchClient = async (id: number) => {
    try {
      const client = await ClientsApi.fetchClient(id);
      setClient(client);
    } catch (err) {
      setError(err)
      throw err;
    }
  }

  const fetchTotalClientCount = async (searchFilter?: string) => {
    const count = await  ClientsApi.fetchTotalClientCount(searchFilter);
    setTotalCount(count);
  }


  const deleteClient = async (id: number) => {
    try {
      await ClientsApi.deleteClient(id);
    } catch (err) {
      setError(err)
      throw err;
    }
   
  }

  const createClient = async (client:  CreateEditClient) => {
    try {
      await ClientsApi.createClient(client)
    } catch (err) {
      setError(err)
      throw err;
    }
  }

  const updateClient = async (data:  CreateEditClient) => {
    try {
      await ClientsApi.updateClient(data)
    } catch (err) {
      setError(err)
      throw err;
    }
  };

  const resetClientError = () => {
    setClientErrorMessage('');
  }

  const clearClients = () => {
    setClients([]);
  }

  const clearClient = () => {
    setClient(undefined);
  }
  return {
    clientLoading: loading,
    clients,
    clientErrorMessage,
    searchValue,
    totalCount,
    client,
    fetchClient,
    fetchTotalClientCount,
    setSearchValue,
    fetchClients,
    deleteClient,
    createClient,
    updateClient,
    resetClientError,
    clearClient,
    clearClients,
  }
}


export default useClientsHook;