import React, { useState } from "react";
import { jwtData, apiEndpoints } from "lib/config";
import {
  AuthResult,
  CreateEditUser,
  LoginUserData,
  LoginUserResult,
  ResetPassword,
  ResetPasswordEmail,
  ResetPasswordErrors,
  SignUpFormErrors,
  User,
} from "types";
import { invalidEmail } from "lib/commonFunctions";
import { UsersApi } from "api";
import { getAPIErrorMessage } from "lib/errorHandling";

interface UserHookInfo {
  passwordValidation: (
    password: string,
    confirmationPassWord: string
  ) => ResetPasswordErrors;
  emailValidation: (email: string) => ResetPasswordErrors;
  userValidation: (
    email: string,
    password: string,
    confirmationPassword: string,
    newUser?: boolean
  ) => SignUpFormErrors;
  fetchAllUsers: () => void;
  fetchUser: () => void;
  createFullUser: (data: CreateEditUser) => void;
  updateUser: (data: CreateEditUser) => void;
  deleteUser: (data: User) => void;
  resetPasswordEmail: (data: ResetPasswordEmail) => void;
  loginUser: (data: LoginUserData) => void;
  logoutUser: () => void;
  users: Array<User>;
  user?: User;
  userErrorMessage?: string;
  resetUserError: () => void;
}

function useUserHook(): UserHookInfo {
  const [users, setUsers] = useState<Array<User>>([]);
  const [user, setUser] = useState<User>();
  const [userErrorMessage, setUserErrorMessage] = useState<string>("");

  const setError = (err: any) => {
    const message = getAPIErrorMessage(err);
    setUserErrorMessage(message);
  };

  const fetchAllUsers = async () => {
    try {
      const users = await UsersApi.fetchAllUsers();
      setUsers(users);
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const createFullUser = async (data: CreateEditUser) => {
    try {
      await UsersApi.createFullUser(data);
      fetchAllUsers();
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const updateUser = async (data: CreateEditUser) => {
    try {
      await UsersApi.updateUser({
        id: data.id || 0,
        firstName: data.firstName ? data.firstName : undefined,
        lastName: data.lastName ? data.lastName : undefined,
        email: data.email ? data.email : undefined,
        company: data.company ? data.company : undefined,
      });
      fetchAllUsers();
    } catch (err) {
      setError(err);
      throw err;
    }
  };
  const deleteUser = async (user: User) => {
    try {
      await UsersApi.deleteUser(user.id);
      fetchAllUsers();
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const resetPasswordEmail = async (data: ResetPasswordEmail) => {
    try {
      await UsersApi.resetPasswordEmail(data);
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const fetchUser = async () => {
    const user = await UsersApi.fetchUser();
    setUser(user);
  };

  const loginUser = async (data: LoginUserData) => {
    const { email, password, captchaToken } = data;

    try {
      const loginResult: AuthResult = await UsersApi.loginUser(data);

      localStorage.setItem(jwtData.authToken, loginResult.data.authToken);
      const { refreshToken } = loginResult.data;
      if (refreshToken) {
        localStorage.setItem(jwtData.refreshToken, refreshToken);
      }

      fetchUser();
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const logoutUser = async () => {
    localStorage.removeItem(jwtData.authToken);
    localStorage.removeItem(jwtData.refreshToken);
  };

  const passwordValidation = (
    password?: string,
    confirmationPassword?: string
  ): ResetPasswordErrors => {
    let errors: ResetPasswordErrors = {};
  
    if (!password) {
      errors.password = "Required";
    } else if (password.length < 8) {
      errors.password = "Less than 8 characters";
    } else if (!/\d/.test(password)) {
      errors.password = "Must contain at least one number";
    } else if (!/[A-Z]/.test(password)) {
      errors.password = "Must contain at least one uppercase letter";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.password = "Must contain at least one special character";
    }
  
    if (confirmationPassword !== password) {
      errors.passwordConfirmation = "Confirm Password does not match";
    }
  
    return errors;
  };
  

  const emailValidation = (email?: string): ResetPasswordErrors => {
    let errors: ResetPasswordErrors = {};
    if (!email) {
      errors.email = "Required";
    }
    else if(invalidEmail(email)){
      errors.email = "Invalid Email"
    }
    return errors;
  };

  const userValidation = (
    email: string,
    password: string,
    confirmationPassword: string,
    newUser = true
  ): ResetPasswordErrors => {
    let errors: SignUpFormErrors = {};

    if (newUser) {
      errors = passwordValidation(password, confirmationPassword);
    }

    if (!email) {
      errors.email = "Required";
    } else if (invalidEmail(email)) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const resetUserError = () => {
    setUserErrorMessage("");
  };

  return {
    fetchAllUsers,
    createFullUser,
    deleteUser,
    updateUser,
    passwordValidation,
    emailValidation,
    userValidation,
    resetPasswordEmail,
    fetchUser,
    loginUser,
    logoutUser,
    resetUserError,
    userErrorMessage,
    users,
    user,
  };
}

export default useUserHook;
