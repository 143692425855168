import React, { FC, useEffect } from "react";
import { AppProvider, useSetAppState, useSetUserState } from "context/index";
import { Routing } from "./routing";
import { fetchUser, userIsAuthorized, logoutUser } from "actions/userActions";

import "./App.scss";

const useGetAuth = async () => {
  const setAppState = useSetAppState();
  const setUserState = useSetUserState();
  useEffect(() => {
    const fetchData = async () => {
      setAppState({ Authenticated: userIsAuthorized(), Authenticating: true });

      try {
        const result = await fetchUser();
        if (result && result.data) {
          setUserState(result.data);
        } else {
          // Logout user if there's no data about them
          logoutUser();
        }
      } catch (err) {
        // Logout user if there's any error verifying they're logged in
        logoutUser();
      }

      setAppState({ Authenticated: userIsAuthorized(), Authenticating: false });
    };

    fetchData();
  }, [setAppState, setUserState]);
};

const App: FC = () => {
  // update User Info
  useGetAuth();

  return (
    <>
      <AppProvider>
        <Routing />
      </AppProvider>
    </>
  );
};

export default App;
