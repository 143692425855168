import { useState, useEffect, ReactElement } from "react";
import styles from "./styles.module.scss";
import { CreateEditUser, User } from "types";
import useUserHook from "hooks/useUsersHook";
import AlertModal from "components/AlertModal";
import ConfirmationModal from "components/ConfirmationModal";
import classNames from "classnames";
import Pagination from "components/pagination";
import { Button } from "components/Buttons";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import { IconContext } from "@react-icons/all-files";
import CreateEditUserModal from "../CreateEditUserModal/CreateEditUserModal";

function UserForm(): ReactElement {
  const [selectedUser, setSelectedUser] = useState<User>();
  const [selectedUserForDelete, setSelectedUserForDelete] = useState<User>();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [openCreateEditUser, setOpenCreateEditUser] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const postsPerPage = 16;
  const maxPageTabs = 8;

  const tableColumns = ["Email", "First Name", "Last Name", "Company", "", ""];

  const {
    users,
    userErrorMessage,
    resetUserError,
    fetchAllUsers,
    deleteUser,
    createFullUser,
    updateUser,
  } = useUserHook();

  const editUser = (user: User) => {
    setSelectedUser(user);
  };

  const onDelete = async (user: User) => {
    setSelectedUserForDelete(user);
    setDeleting(true);
  };

  const onDecline = () => {
    setDeleting(false);
    setSelectedUserForDelete(undefined);
  };

  const onConfirm = () => {
    setDeleting(false);
    if (selectedUserForDelete) {
      deleteUser(selectedUserForDelete);
    }
    setSelectedUserForDelete(undefined);
  };

  const toggleCreateEditUser = () => {
    setOpenCreateEditUser((prev) => !prev);
  };

  const onCreateEditUser = async (user: CreateEditUser) => {
    await createFullUser(user);
  };

  const onUpdateUser = async (user: CreateEditUser) => {
    await updateUser(user);
  };

  const onEditCreateClose = () => {
    setSelectedUser(undefined);
    setOpenCreateEditUser(false);
  };

  useEffect(() => {
    setOpenCreateEditUser(!!selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div>
      <div className={styles.tableFilters}>
        <div>
          <Button
            onClick={toggleCreateEditUser}
            className={classNames(styles.baseButton, styles.createUserButton)}
          >
            {"Create User"}
          </Button>
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <tr key="HeaderRow">
            {tableColumns.map((col: string) => {
              return <th key={col}>{col}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {users.map((user: User, index: number) => {
            return (
              index >= postsPerPage * (currentPage - 1) &&
              index < postsPerPage * currentPage && (
                <tr key={user.id} className={styles.striped}>
                  <td>{user.email}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.company}</td>
                  <td className={styles.iconDiv}>
                    <button
                      onClick={() => editUser(user)}
                      className={styles.tableButton}
                    >
                      <IconContext.Provider value={{ size: "2em" }}>
                        <FaEdit />
                      </IconContext.Provider>
                    </button>
                  </td>
                  <td className={styles.iconDiv}>
                    <button
                      onClick={() => onDelete(user)}
                      className={styles.tableButton}
                    >
                      <IconContext.Provider value={{ size: "2em" }}>
                        <AiOutlineDelete />
                      </IconContext.Provider>
                    </button>
                  </td>
                </tr>
              )
            );
          })}
        </tbody>
      </table>
      <div className={styles.incrementDiv}>
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={users.length}
          changePage={setCurrentPage}
          maxPageTabs={maxPageTabs}
          currentPage={currentPage}
        />
      </div>
      <ConfirmationModal
        open={!!deleting}
        onConfirm={onConfirm}
        onDecline={onDecline}
        title={"Deleting User"}
        message={"Are you sure you wish to delete this User?"}
      />
      <CreateEditUserModal
        open={!!openCreateEditUser}
        onCreateEditUser={onCreateEditUser}
        user={selectedUser}
        onUpdate={onUpdateUser}
        onClose={onEditCreateClose}
      />
      <AlertModal
        open={!!userErrorMessage}
        onClose={resetUserError}
        title={"Error"}
        message={userErrorMessage}
      />
    </div>
  );
}

export default UserForm;
