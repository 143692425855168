/// Base Actions - calling external API
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import _keys from "lodash.keys";
import { baseAPIUrl, jwtData } from "lib/config";
import { wrapRefreshAuthTokenOnFail } from "./jWTCalls";
import { logoutUser, userIsAuthorized } from "actions/userActions";

const config = {
  authToken: jwtData && jwtData.authToken ? jwtData.authToken : "authToken",
  refreshToken:
    jwtData && jwtData.refreshToken ? jwtData.refreshToken : "refreshToken",
};

const fullEndpoint = (endpoint: string) => {
  if (endpoint && endpoint.startsWith("http")) {
    return `/${endpoint}`;
  } else {
    return `${baseAPIUrl}/${endpoint}`;
  }
};

/// Get from endpoint
const get = <T>(endpoint: string, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios
      .get<any, AxiosResponse<T>>(fullEndpoint(endpoint), getConfig(params))
      .catch(function (error) {
        errorHandling(error);
      });
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// Get from endpoint
const getWSpecificToken = <T>(endpoint: string, token: string) => {
  const callAxios = () => {
    return axios
      .get<any, AxiosResponse<T>>(
        fullEndpoint(endpoint),
        getConfigWSpecificToken(token)
      )
      .catch(function (error) {
        errorHandling(error);
      });
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// get Blob from endpoint
const getBlob = (endpoint: string, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios
      .get(
        fullEndpoint(endpoint),
        getConfig({ responseType: "blob", ...params })
      )
      .catch(function (error) {
        errorHandling(error);
      });
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post data to endpoint
const post = (endpoint: string, data: any, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios
      .post(fullEndpoint(endpoint), data, getConfig(params))
      .catch(function (error) {
        errorHandling(error);
      });
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post file data to endpoint
const postFile = (endpoint: string, data: any, params?: AxiosRequestConfig) => {
  let formData = new FormData();
  let keys = _keys(data);
  let config = getConfig({ "content-type": "multipart/form-data", ...params });

  keys.forEach((k: any) => {
    formData.append(k, data[k]);
  });

  const callAxios = () => {
    return axios
      .post(fullEndpoint(endpoint), formData, config)
      .catch(function (error) {
        errorHandling(error);
      });
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// post file data to endpoint
const putFile = (endpoint: string, data: any, params?: AxiosRequestConfig) => {
  let formData = new FormData();
  let keys = _keys(data);
  let config = getConfig({ "content-type": "multipart/form-data", ...params });

  keys.forEach((k: any) => {
    formData.append(k, data[k]);
  });

  const callAxios = () => {
    return axios
      .put(fullEndpoint(endpoint), formData, config)
      .catch(function (error) {
        errorHandling(error);
      });
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// put data to endpoint
const put = (endpoint: string, data: any, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios
      .put(fullEndpoint(endpoint), data, getConfig(params))
      .catch(function (error) {
        errorHandling(error);
      });
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// delete endpoint
const del = (endpoint: string, params?: AxiosRequestConfig) => {
  const callAxios = () => {
    return axios
      .delete(fullEndpoint(endpoint), getConfig(params))
      .catch(function (error) {
        errorHandling(error);
      });
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// create general http request
const request = (config: AxiosRequestConfig) => {
  let newConfig = { ...getConfig(), ...config };

  const callAxios = () => {
    return axios.request(newConfig)
    .catch(function (error) {
      errorHandling(error);
    });
  };

  return wrapRefreshAuthTokenOnFail(callAxios);
};

/// Private Functions
const getHeaders = () => {
  // we store the auth for accessing the api in authToken
  const token = localStorage.getItem(config.authToken);
  let headers: { Authorization?: string } = {};

  if (token) {
    headers.Authorization = "Bearer " + token;
  }

  return headers;
};

const getHeadersWSpecificToken = (specificToken: string) => {
  let headers: { Authorization?: string } = {};
  headers.Authorization = "Bearer " + specificToken;

  return headers;
};

const getConfig = (overrideParams = {}) => {
  return {
    headers: getHeaders(),
    ...overrideParams,
  };
};

const getConfigWSpecificToken = (specificToken: string) => {
  return {
    headers: getHeadersWSpecificToken(specificToken),
  };
};

function errorHandling(error: any) {
  if (error?.response?.status === 401 && userIsAuthorized()) {
    logoutUser();
    window.location.reload();
    return;
  }
  throw error
}

export {
  get,
  getWSpecificToken,
  getBlob,
  post,
  postFile,
  put,
  putFile,
  del,
  request,
};
