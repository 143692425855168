import React, { FC, ReactElement, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ClientsHook from "hooks/useClientsHook";
import styles from "./styles.module.scss";
import OrderRow from "components/OrderRow";
import { Client, OrderBatchFields, OrderInformation, ProductFields,} from "types";
import useOrdersHook from "hooks/useOrdersHook";
import AlertModal from "components/AlertModal";
import { BsCalendar } from "@react-icons/all-files/bs/BsCalendar";
import { formatISODateOnly } from "lib/commonFunctions";
import classNames from "classnames";
import ClientSelect from "components/ClientSelect";
import useDebounceHook from "hooks/useDebounceHook";
import { Button } from "components/Buttons";
import TreeProducts from "pages/TreeProducts";
import PlasticsSettings from "components/PlasticsSettings";

const defaultBatch = {
  id: 1,
  quantity: 0,
  container: "",
  containerAmount: 0,
};

const tableColumns = [
  "Product Name",
  "Quantity",
];
const ProductTypes = ["Coffee Pods", "Other"];

const successMessage = "Order Creation was successful";

function AddNewOrderForm(): ReactElement {
  const initOrderRows = ProductTypes.map((prod) => {
    return { productName: prod, batches: [{ ...defaultBatch }] };
  });

  const [selectedClient, setSelectedClient] = useState<Client>();
  const [chosenDate, setChosenDate] = useState<Date>();
  const [createErrorMessage, setCreateErrorMessage] = useState<string>();
  const [searchFilter, setSearchFilter] = useState<string>();
  const [alertTitle, setAlertTitle] = useState<string>();
  const [alertMessage, setAlertMessage] = useState<string>();
  const [coffeePods, setCoffeePods] = useState<number>();
  const [otherAmount, setOtherAmount] = useState<number>();
  const [treeAmount, setTreeAmount] = useState<number>();

  const [openPlasticsPriceChange, setOpenPlasticsPriceChange] = useState<boolean>(false);

  const [alertModalOpen, setAlertModalOpen] =useState<boolean>(false);
  const debounceSearch = useDebounceHook(searchFilter);

  const { clients, fetchClients } = ClientsHook();

  const { orderErrorMessage, createOrder, clearError } = useOrdersHook();

  const clearAlert = () => {
    clearError();
    setCreateErrorMessage(undefined);
    setAlertModalOpen(false);
  }

  const buildOrder = async() => {
    if(alertModalOpen) return;
    if (!chosenDate) {
      setCreateErrorMessage(`Please select an order date value`);
    } else if (!selectedClient && !treeAmount) {
      setCreateErrorMessage(`Please select a client or add trees`);
    } else if (!coffeePods && !otherAmount && !treeAmount) {
      setCreateErrorMessage(`Please provide order values`);
    } else {
      const date = formatISODateOnly(chosenDate);
      if (!date) return;

      await createOrder({
        clientId: selectedClient?.id,
        date,
        coffeePods: coffeePods,
        other: otherAmount,
        trees: treeAmount
      });
      setAlertTitle("Success!")
      setAlertMessage(successMessage)
      setAlertModalOpen(true)
      clearAll()
    }
  };

  const clearAll = () => {
    setSelectedClient(undefined)
    setChosenDate(undefined)
    setCoffeePods(undefined)
    setTreeAmount(undefined)
    setOtherAmount(undefined)
    setSearchFilter(undefined)
  }

  useEffect(() => {
    fetchClients({limit: 50, searchFilter: debounceSearch});
  }, [debounceSearch])

  useEffect(()=>{
    const open = !!(orderErrorMessage || createErrorMessage)
    setAlertTitle("Error!")
    setAlertMessage(createErrorMessage || orderErrorMessage)
    setAlertModalOpen(open)
  }, [orderErrorMessage, createErrorMessage])

  return (
    <div>
      <div className={styles.tableFilters}>
        <div className={styles.fields}>
          <BsCalendar />
          <DatePicker
            className={styles.datePick}
            openToDate={chosenDate}
            selected={chosenDate}
            onChange={(date: Date) => setChosenDate(date)}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            scrollableMonthYearDropdown
            placeholderText={"Please select an order date"}
          />
         
        </div>
        <div className={styles.searchBar}>
          <div className={styles.searchIcons}></div>
          <ClientSelect
            updateSelectedClient={(client?: Client) => setSelectedClient(client)}
            clientList={clients}
            setValue={setSearchFilter}
            value={searchFilter}
            selectedClient={selectedClient}
          />
        </div>
        <div className={styles.plasticsChange}>
          <Button 
            onClick={() => setOpenPlasticsPriceChange(true)}
            className={styles.button}>
              Change Plastic Price
          </Button>
        </div>
      
      </div>
      <div>
        <table className={styles.orderTable}>
          <thead>
            <tr key="HeaderRow">
              {tableColumns.map((col: string) => {
                return <th key={col}>{col}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {!!selectedClient?.id && (
              <>
                <OrderRow product={"Coffee Pods Recycled (lbs)"} setProduct={setCoffeePods} value={coffeePods} />
                <OrderRow product={"Other Waste Diverted (lbs)"} setProduct={setOtherAmount} value={otherAmount}/>
              </>
            )}
            <OrderRow product={"Trees Planted"} setProduct={setTreeAmount} value={treeAmount} />
          </tbody>
        </table>
      </div>     
   
      <Button 
        onClick={buildOrder} 
        className={classNames(styles.orderButton,styles.baseButton)}>
          Create Order
      </Button>
      <PlasticsSettings
        open={openPlasticsPriceChange}
        onClose={() => setOpenPlasticsPriceChange(false)}
      />
        <AlertModal
          open={alertModalOpen}
          onClose={clearAlert}
          title={alertTitle}
          message={alertMessage}
        />
    </div>
  );
}

export default AddNewOrderForm;
