import * as base from "./lib/baseActions";
import { jwtData, apiEndpoints } from "lib/config";
import {
  AuthResult,
  CreateEditUser,
  LoginUserData,
  LoginUserResult,
  ResetPassword,
  SignupUserData,
  UserEmail,
} from "types";

/// Fetch data for logged in user
// remove
const fetchUser = () => {
  return base.get(apiEndpoints.usersMe);
};

/// Login
/// data = { email:"login email", password: "login pass" }
const loginUser = async (data: LoginUserData): Promise<LoginUserResult> => {
  const { email, password, captchaToken } = data;

  try {
    const loginResult: AuthResult = await base.post(apiEndpoints.login, {
      email,
      password,
      captchaToken,
    });

    localStorage.setItem(jwtData.authToken, loginResult.data.authToken);
    const { refreshToken } = loginResult.data;
    if (refreshToken) {
      localStorage.setItem(jwtData.refreshToken, refreshToken);
    }

    return fetchUser();
  } catch (err) {
    if (err.status === 400) {
      err.message = "Incorrect Email or Password.";
    }

    return Promise.resolve(err);
  }
};

/// logout user
const logoutUser = async () => {
  localStorage.removeItem(jwtData.authToken);
  localStorage.removeItem(jwtData.refreshToken);

  return Promise.resolve();
};

// create login user
// data : { email: "email@email.com", password:"password", confirmPassword:"password" }
const createUser = async (data: SignupUserData) => {
  const registrationCheck = checkRegistrationInformation(data);

  if (!registrationCheck.success) {
    return Promise.reject(registrationCheck.errors);
  } else {
    try {
      const userResult = await base.post(apiEndpoints.users, data);
      return userResult;
    } catch (err) {
      return Promise.reject(err?.original?.response);
    }
  }
};

// create login user with all info
// data : { email: "email@email.com", firstName: "first", lastname: "last"  company: "company", password:"password", confirmPassword:"password" }
const createFullUser = async (data: CreateEditUser) => {
  const registrationCheck = checkRegistrationInformation(data);

  if (!registrationCheck.success) {
    return Promise.reject(registrationCheck.errors);
  } else {
    try {
      const userResult = await base.post(apiEndpoints.users, data);
      return userResult;
    } catch (err) {
      return Promise.reject(err?.original?.response);
    }
  }
};

/// check confirmation helper function
const checkRegistrationInformation = (data: SignupUserData) => {
  let check: { success: boolean; errors?: any } = { success: true };
  let errors = [];
  if (!data.email || data.email.length < 5) {
    // does not have email
    errors.push(new Error("You must fill in a valid email"));
  }

  if (!data.password || !data.passwordConfirmation) {
    errors.push(
      new Error("You must fill in the password and password confirmation field")
    );
  } else if (data.password.length < 8) {
    errors.push(new Error("The password must be a minimum length of 8"));
  } else if (data.password !== data.passwordConfirmation) {
    errors.push(new Error("The password and confirmation password must match"));
  }

  if (errors.length > 0) {
    check.success = false;
    check.errors = errors;
  }

  return check;
};

// reset password
// data : { userId:"number", "password:"password", confirmPassword:"password" }
const resetPassword = async (data: ResetPassword) => {
  const resetPassword = checkResetPasswordInformation(data);
  if (!resetPassword.success) {
    return Promise.reject(resetPassword.errors);
  } else {
    try {
      const userResult = await base.put(apiEndpoints.userPasswordReset, data);
      return userResult;
    } catch (err) {
      return Promise.reject(err?.original?.response);
    }
  }
};

// change password
// data : { userId:"number", oldPassword: "oldPassword", "password:"password", confirmPassword:"password" }
const changePassword = async (data: ResetPassword) => {
  const changePassword = checkResetPasswordInformation(data);
  if (!changePassword.success) return Promise.reject(changePassword.errors);
  try {
    return await base.put(apiEndpoints.userPasswordChange, data);
  } catch (err) {
    return Promise.reject(err?.original?.response);
  }
};

/// check confirmation helper function
const checkResetPasswordInformation = (data: ResetPassword) => {
  let check: { success: boolean; errors?: any } = { success: true };
  let errors = [];

  if (!data.newPassword || !data.passwordConfirmation) {
    errors.push(
      new Error(
        "You must fill in the new password and password confirmation field"
      )
    );
  } else if (data.newPassword.length < 8) {
    errors.push(new Error("The password must be a minimum length of 8"));
  } else if (data.newPassword !== data.passwordConfirmation) {
    errors.push(new Error("The password and confirmation password must match"));
  }

  if (errors.length > 0) {
    check.success = false;
    check.errors = errors;
  }

  return check;
};

// check if user is Authorized
const userIsAuthorized = () => {
  const initialAuthToken =
    localStorage && localStorage.getItem(jwtData.authToken);
  return initialAuthToken ? true : false;
};

// get users
// returns all users in the DB
const fetchAllUsers = () => {
  return base.get(apiEndpoints.usersAll);
};

const deleteUserByEmail = async (data: UserEmail) => {
  return base.put(apiEndpoints.deleteUserByEmail, data);
};

export {
  fetchUser,
  fetchAllUsers,
  deleteUserByEmail,
  loginUser,
  logoutUser,
  createUser,
  createFullUser,
  userIsAuthorized,
  resetPassword,
  changePassword,
};
