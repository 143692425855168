import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

interface paginationProps {
  postsPerPage: number;
  totalPosts: number;
  currentPage: number;
  maxPageTabs: number;
  changePage: (newPage: number) => void;
}

const Pagination = ({ postsPerPage, totalPosts, currentPage, maxPageTabs, changePage,}: paginationProps) => {

  const pageNumbers = [];
  const maxPages = Math.ceil(totalPosts / postsPerPage);
  
  let count = 0
  let page = 1
  while (count < maxPageTabs && page <= maxPages) {
    
    if (currentPage < maxPageTabs / 2 || page >= currentPage - maxPageTabs / 2 || page > maxPages - maxPageTabs) {
      pageNumbers.push(page);
      count++
    } 
    page++;

  }

  const decrementPage = () =>{
    const nextPage = Math.max(currentPage - 1, 1)
    changePage(nextPage)
  }
  const incrementPage = () => {
    const nextPage = Math.min(currentPage + 1, Math.ceil(totalPosts / postsPerPage))
    changePage(nextPage)
  }

  return (
    <nav className={styles.paginationContainer}>
      <ul className={styles.pagination}>
        {(!pageNumbers.includes(1) && pageNumbers.length > 0) && (
          <li key="previous" className={styles.pageTile} onClick={decrementPage}>
          <a>
            {"<"}
          </a>
          </li>
        )}
        {!pageNumbers.includes(1) &&
         <li key="startEllipse" className={styles.ellipse} onClick={() => changePage(1)}>
          <a >{"..."}</a>
        </li>}
        {pageNumbers.map((number) => (
          <li key={number} 
            className={classNames(number==currentPage? styles.currentPageTile: styles.pageTile)}
            onClick={() => changePage(number)}
            >
            <a >
              {number}
            </a>
          </li>
        ))}
        {!pageNumbers.includes(maxPages) && <li key="endEllipse" className={styles.ellipse}>
          <a onClick={() => changePage(maxPages)}>{"..."}</a>
        </li>}
        {(maxPages - currentPage > postsPerPage) && (

            <li key="next" className={styles.pageTile} onClick={incrementPage}>
            <a>
              {">"}
            </a>
          </li>
        )}
        
      </ul>
    </nav>
  );
};

export default Pagination;
