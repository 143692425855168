import { useState, useEffect, SetStateAction, ReactElement } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "App/routing";
import SearchBar from "components/SearchBar";
import CalendarRange from "components/CalendarRange";

import styles from "./styles.module.scss";
import { Client, Impact } from "types";
import classNames from "classnames";
import { FaRecycle } from "@react-icons/all-files/fa/FaRecycle";
import useImpactsHook from "hooks/useImpactsHook";
import useDebounceHook from "hooks/useDebounceHook";
import { BsSearch } from "@react-icons/all-files/bs/BsSearch";
import useClientsHook from "hooks/useClientsHook";
import ClientAutocomplete from "components/ClientAutocomplete";
import Pagination from "components/pagination";
import { formatISODateOnly } from "lib/commonFunctions";
import { IconContext } from "@react-icons/all-files";
import { getPodEquivalences, getWasteEquivalences } from "lib/impact";
import AlertModal from "components/AlertModal";
import MultiplePresetDatePicker from "components/MultiplePresetDatePicker";

function HomeDetails(): ReactElement {
  const [minDate, setMinDate ] = useState<Date>();
  const [maxDate, setMaxDate ] = useState<Date>();
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [amountOfPages, setAmountOfPages] = useState<number>(0);
  const [selectedClient, setSelectedClient] = useState<Client>();

  const history = useHistory();
 
  const postsPerPage = 25;
  const maxPageTabs = 7; 

  const { clients, fetchClients } = useClientsHook();

  const debounceSearch = useDebounceHook(searchFilter);


  const {
    fetchImpacts,
    totalCount,
    fetchTotalImpactsCount,
    impacts,
    impactErrorMessage,
    resetImpactError
  } = useImpactsHook();



  const tableColumns = [
    "Client Name",
    "Coffee Pods Recycled (lbs)",
    "Coffee Pods in #",
    "CN Tower Equivalents",
    "Other Waste Diverted (lbs) ",
    "Total Waste Diverted (lbs) ",
    "Trees Planted",
    "Plastic Diverted",
    ""
  ];
  const showImpact = (impact: Impact) => {
    history.push({
      pathname: RoutePaths.Impacts,
      state: { clientId: impact.clientId },
    });
  };

  useEffect(() => {
    const fromPage = (currentPage - 1) * postsPerPage;
    const params = {
      minDate: formatISODateOnly(minDate),
      maxDate: formatISODateOnly(maxDate), 
      searchFilter,
      limit: postsPerPage,
      from: fromPage < 0 ? 0 : fromPage
    }
    fetchTotalImpactsCount(params);
    fetchImpacts(params);
  }, [currentPage])

  useEffect(() => {
    if (!selectedClient) return
    setSearchFilter(selectedClient?.clientName)
  }, [selectedClient])

  useEffect(() => {
    const params = {
      minDate: formatISODateOnly(minDate),
      maxDate: formatISODateOnly(maxDate), 
      searchFilter,
      limit: postsPerPage,
    }
   
    fetchTotalImpactsCount(params);
    fetchImpacts(params);
    fetchClients({searchFilter: debounceSearch, limit: 20})
  }, [debounceSearch, minDate, maxDate])


  return (
    <div className={styles.homePageInfo}>
      <div className={styles.headerSection}>
        <div className={styles.searchBar}>
          <ClientAutocomplete
            updateSelectedClient={setSelectedClient}
            clientList={clients}
            setValue={setSearchFilter}
            value={searchFilter}
          />
        </div>
        <MultiplePresetDatePicker
          maxDate={maxDate}
          minDate={minDate}
          setMaxDate={setMaxDate}
          setMinDate={setMinDate}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            {tableColumns.map((col: string) => {
              return <th key={col}>{col}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {impacts.map((impact: Impact, index: number) => {
            return (
                <tr key={impact.id} className={styles.striped}>
                  <td className={styles.tableColumn}>{impact.clientName}</td>
                  <td className={styles.tableColumn}>{impact.coffeePods}</td>
                  <td className={styles.tableColumn}>{impact.coffeePodsCount}</td>
                  <td className={styles.tableColumn}>{impact.cnTower}</td>
                  <td className={styles.tableColumn}>{impact.otherWasteAvoided}</td>
                  <td className={styles.tableColumn}>{impact.totalWasteAvoided}</td>
                  <td className={styles.tableColumn}>{impact.trees}</td>
                  <td className={styles.tableColumn}>{impact.plastic}</td>
                  <td className={styles.iconDiv}>
                    <button 
                      onClick={() => showImpact(impact)}
                      className={styles.impactButton}
                    >
                      <IconContext.Provider value={{size: "2em"}}>
                        <FaRecycle/>
                      </IconContext.Provider>
                    </button>
                  </td>
                </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <div className={styles.incrementDiv}>
          <Pagination 
            postsPerPage={postsPerPage} 
            totalPosts={totalCount} 
            changePage={setCurrentPage}
            maxPageTabs={maxPageTabs}
            currentPage={currentPage}
          />
        </div>
      </div>
      <AlertModal
        open={!!impactErrorMessage}
        onClose={resetImpactError}
        title={"Error"}
        message={impactErrorMessage}
      />
    </div>
  );
}

export default HomeDetails;
