import { ReactElement, SetStateAction, useEffect, useState } from "react";
import Select from 'react-select'

import styles from "./styles.module.scss";
import {Client } from "types";
import { BigIntStats } from "fs";
import { BsSearch } from "@react-icons/all-files/bs/BsSearch";

interface ClientDropDownParams {
    updateSelectedClient: (client?:Client ) => void
    clientList:Client[]
    setValue: (value: string) => void;
    value?: string;
    selectedClient?: Client;
  }

function ClientDropDown({updateSelectedClient, setValue, clientList, value, selectedClient}: ClientDropDownParams ): ReactElement {
  const [selectedValue, setSelectedClient] = useState<any>({label: '--- House Account ---', value: 0})
  const clientNames = [{label: '--- House Account ---', value: 0}]
  clientList.forEach(client => {
    clientNames.push({label: client.clientName, value: client.id})
  })


  const handleChange = (e: any) => {
    const client = clientList.find((client) => client.id == e.value);
    setSelectedClient(e)
    updateSelectedClient(client);
  }

  
  return (
    <div className={styles.clientDropdownSection}>
      <Select 
        className={styles.selectContainer} 
        aria-labelledby="dropdownMenuLink" 
        placeholder='Please select a client'
        onChange={handleChange} 
        options={clientNames}
        defaultValue={clientNames[0]}
        isSearchable={true}
        onInputChange={(value) => setValue(value)}
        inputValue={value}
        value={selectedClient ? selectedValue : clientNames[0]}
      />
    </div>
  );
};

export default ClientDropDown;

