import { ReactElement, useEffect, useState } from "react";
import { Formik, Form, FormikHelpers, useFormikContext } from "formik";
import { Button } from "components/Buttons";
import { TextField } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import {
  CreateEditUser, User } from "types";
import useUserHook from "hooks/useUsersHook";
import BaseModal from "components/BaseModal";

interface CreateFullUserProp {
  open: boolean;
  onCreateEditUser(values: CreateEditUser): void;
  onUpdate(values: CreateEditUser): void;
  user?: User;
  onClose: () => void;
}

function CreateEditUserModal({
  open,
  onCreateEditUser,
  onUpdate,
  user,
  onClose,
}: CreateFullUserProp): ReactElement {
  const initialValues: CreateEditUser = {
    email: "",
    firstName: "",
    lastName: "",
    company: "",
    password: "",
    passwordConfirmation: "",
  };

  const [signUpError, setCreateEditUserError] = useState<string | null>(null);
  const [values, setValues] = useState<CreateEditUser>(initialValues);
  const { userValidation } = useUserHook();

  const validate = (values: CreateEditUser) => {
    return userValidation(
      values.email,
      values.password,
      values.passwordConfirmation,
      !values.id
    );
  };

  const setValuesToUser = () => {
    let values = { ...initialValues };
    if (user) {
      values.id = user.id;
      values.email = user.email;
      values.firstName = user.firstName;
      values.lastName = user.lastName;
      values.company = user.company;
    }

    setValues(values);
  };

  const onSubmit = async (
    values: CreateEditUser,
    { setSubmitting }: FormikHelpers<CreateEditUser>
  ) => {
    setCreateEditUserError(null);
    setSubmitting(true);
    if (user) {
      await onUpdate(values);
    } else {
      await onCreateEditUser(values);
    }

    setSubmitting(false);
    onClose();
  };

  useEffect(() => {
    setValuesToUser();
  }, [user]);

  return (
    <>
      {open && (
        <BaseModal>
          <Formik
            initialValues={values}
            validate={validate}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting, errors }) => (
              <Form className={styles.modal}>
                <TextField
                  type="email"
                  name="email"
                  label="Email"
                  className={styles.field}
                  labelClassName={
                    errors.email ? styles.fieldError : styles.labels
                  }
                />
                <TextField
                  type="text"
                  name="firstName"
                  label="FirstName"
                  className={styles.field}
                  labelClassName={styles.labels}
                />
                <TextField
                  type="text"
                  name="lastName"
                  label="LastName"
                  className={styles.field}
                  labelClassName={styles.labels}
                />
                <TextField
                  type="text"
                  name="company"
                  label="Company"
                  className={styles.field}
                  labelClassName={styles.labels}
                />
                {!user && (
                  <>
                    <TextField
                      type="password"
                      name="password"
                      label="Password"
                      className={styles.field}
                      labelClassName={
                        errors.password ? styles.fieldError : styles.labels
                      }
                    />
                    <TextField
                      type="password"
                      name="passwordConfirmation"
                      label="Confirm Password"
                      className={styles.field}
                      labelClassName={styles.labels}
                    />
                  </>
                )}
                <div className={styles.buttonSection}>
                  <Button
                    className={styles.closeButton}
                    type="reset"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    className={styles.submitButton}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
                {signUpError && <div>{signUpError}</div>}
              </Form>
            )}
          </Formik>
        </BaseModal>
      )}
    </>
  );
}

export default CreateEditUserModal;
