import classNames from "classnames";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss"
import { RoutePaths } from "App/routing";

  
interface HeaderButtonProps {
    textTag: string;
    defaultText: string;
    icon?: ReactElement;
    onClick: () => void
    style: number;
} 

function HeaderButton({textTag,defaultText, icon, style, onClick}: HeaderButtonProps):ReactElement {
    return(
        <div className={ styles.buttonContainer }>
              <button
                className={classNames(styles.baseButton, styles.hoverOverButton, style == 1 ? styles.style1 : styles.style2)}
                onClick={onClick}
                key={textTag}
              >
                {defaultText} {icon && (icon)}
              </button>
        </div>
    )
}

export default HeaderButton