import React, { FC } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "pages/Home";
import Login from "pages/Login";
import SignUp from "pages/SignUp";
import ResetPassword from "pages/ResetPassword";
import { useAppState } from "context";
import Impacts from "pages/Impacts";
import AddNewOrder from "pages/AddNewOrder";
import TreeProducts from "pages/TreeProducts";
import ClientReports from "pages/ClientReports";
import ManageClient from "pages/ManageClient";
import ManageUser from "pages/ManageUser";
import SpendTracker from "pages/SpendTracker";
import CreditMemos from "pages/CreditMemos";
import { jwtData, apiEndpoints } from "lib/config";
import { userIsAuthorized } from "actions/userActions";

export enum RoutePaths {
  Home = "/",
  Login = "/login",
  SignUp = "/signUp",
  ResetPassword = "/ResetPassword",
  EncryptedContent = "/encryptedContent",
  AddNewOrder = "/AddNewOrder",
  TreeProducts = "/TreeProducts",
  Impacts = "/Impacts",
  ClientReports = "/ClientReports",
  ManageClient = "/ManageClient",
  ManageUser = "/ManageUser",
  SpendTracker = "/SpendTracker",
  CreditMemos = "/CreditMemos"
}

const AuthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path={RoutePaths.ResetPassword}>
        <ResetPassword />
      </Route>
      <Route path={RoutePaths.ManageUser}>
        <ManageUser />
      </Route>
      <Route path={RoutePaths.Impacts}>
        <Impacts />
      </Route>
      <Route path={RoutePaths.AddNewOrder}>
        <AddNewOrder />
      </Route>
      <Route path={RoutePaths.CreditMemos}>
        <CreditMemos />
      </Route>
      <Route path={RoutePaths.ClientReports}>
        <ClientReports />
      </Route>
      <Route path={RoutePaths.TreeProducts}>
        <TreeProducts />
      </Route>
      <Route path={RoutePaths.ManageClient}>
        <ManageClient />
      </Route>
      <Route path={RoutePaths.SpendTracker}>
        <SpendTracker />
      </Route>
      <Route path={RoutePaths.Home}>
        <Home />
      </Route>
    </Switch>
  );
};

export const Routing: FC = () => {
  const appState = useAppState();
  return (
    <Router>
      <Switch>
        <Route path={RoutePaths.Login}>
          <Login />
        </Route>
        <Route path={RoutePaths.ResetPassword}>
          <ResetPassword />
        </Route>
        <Route
          render={({ location }) => {
            if (appState.Authenticating && !appState.Authenticated) {
              return <>null</>;
            } else if (appState.Authenticated) {
              return <AuthenticatedRoutes />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: RoutePaths.Login,
                    state: { from: location },
                  }}
                />)
              }
            }}
          />
        </Switch>
    </Router>
  );
};
