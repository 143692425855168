import React, { FC, useState, useEffect, ReactElement } from "react";
import { useLocation, withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.scss";
import CalendarRange from "components/CalendarRange";
import useImpactsHook from "hooks/useImpactsHook";
import {
  addDaysFromCurrentDate,
  addMonthsFromCurrentDate,
  formatISODateOnly,
  formatOneDecimal,
  todaysDate,
} from "lib/commonFunctions";
import classNames from "classnames";
import { CoffeePods, Trees, Waste, WasteBin, CnTower } from "components/Images";
import useClientsHook from "hooks/useClientsHook";
import AlertModal from "components/AlertModal";
import MultiplePresetDatePicker from "components/MultiplePresetDatePicker";
import ClientAutocomplete from "components/ClientAutocomplete";
import useDebounceHook from "hooks/useDebounceHook";
import { Client } from "types";

enum BUTTON_VALUES {
  Total = 0,
  PastMonth = 1,
  PastThreeMonths = 2,
  PastYear = 3,
  CustomRange = 4,
}

function ImpactsForm(): ReactElement {
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const [selectedClientId, setSelectedClientId] = useState<number>();
  const [selectedClientSearch, setSelectedClientSearch] = useState<Client>();
  const location: any = useLocation();
  const clientId = location.state?.clientId;

  const { 
    impact, 
    fetchImpact, 
    impactErrorMessage, 
    resetImpactError 
  } =
    useImpactsHook();

  const {
    client,
    fetchClient,
    fetchClients,
    clients,
    clientErrorMessage,
    resetClientError,
    setSearchValue,
    searchValue,
    clearClient,
  } = useClientsHook();

  const searchDebounce = useDebounceHook(searchValue);

  useEffect(() => {
    fetchClients({ searchFilter: searchDebounce, limit: 16 });
  }, [searchDebounce]);

  useEffect(() => {
    setSelectedClientId(selectedClientSearch?.id);
  }, [selectedClientSearch]);

  useEffect(() => {
    if (clientId && !selectedClientId) return
    fetchImpact({
      clientId: selectedClientId,
      minDate: formatISODateOnly(minDate),
      maxDate: formatISODateOnly(maxDate),
    });
  }, [minDate, maxDate, selectedClientId]);

  useEffect(() => {
    if (!selectedClientId) {
      clearClient();
      return;
    }
    fetchClient(selectedClientId);
  }, [selectedClientId]);

  useEffect(() => {
    setSelectedClientId(clientId);
  }, []);

  return (
    <div>
      <div className={styles.clientName}>
        {client?.clientName ? client?.clientName : "Total Company Impact"}
      </div>
      <div className={styles.headerSection}>
        <div className={styles.searchBar}>
          <ClientAutocomplete
            updateSelectedClient={setSelectedClientSearch}
            clientList={clients}
            setValue={setSearchValue}
            value={searchValue}
          />
          <MultiplePresetDatePicker
            maxDate={maxDate}
            minDate={minDate}
            setMaxDate={setMaxDate}
            setMinDate={setMinDate}
          />
        </div>
      </div>
      <table className={styles.impactTable}>
        <tbody>
          <tr key="impacts">
            <td key="Pods">
              <div className={styles.info}>{"Coffee Pods Recycled"}</div>
              <div className={styles.combinedData}>
                <div>Total Pounds:</div>
                <div>{impact?.coffeePods}</div>
              </div>
              <div className={styles.combinedData}>
                <div>Total Number:</div>
                <div>{impact?.coffeePodsCount}</div>
              </div>
              <div className={styles.iconDiv}>
                <CoffeePods />
              </div>
            </td>
            <td key="CNT">
              <div className={styles.info}>{"CN Tower Equivalents:"}</div>
              <div>{impact?.cnTower}</div>
              <div className={styles.iconDiv}>
                <CnTower />
              </div>
            </td>
            <td key="Other Waste">
              <div className={styles.info}>{"Waste Diverted"}</div>
              <div className={styles.combinedData}>
                <div>Other Waste:</div>
                <div>{impact?.otherWasteAvoided}<span>lbs</span></div>
              </div>
              <div className={styles.combinedData}>
                <div>Total Waste:</div>
                <div>{impact?.totalWasteAvoided}<span>lbs</span></div>
              </div>
              
              <div className={styles.iconDiv}>
                <WasteBin />
              </div>
            </td>
            <td key="Trees">
              <div className={styles.info}>{"Trees Planted:"}</div>
              <div>{impact?.trees}</div>
              <div className={styles.iconDiv}>
                <Trees />
              </div>
            </td>
            <td key="Plastic">
              <div className={styles.info}>{"Plastic Diverted (lbs):"}</div>
              <div>
                {impact?.plastic}
              </div>
              <div className={styles.iconDiv}>
                <Waste />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {!impact && <div className={styles.searching}>Searching...</div>}
      <AlertModal
        open={!!(clientErrorMessage || impactErrorMessage)}
        onClose={() => {
          resetClientError();
          resetImpactError();
        }}
        title={"Error"}
        message={clientErrorMessage || impactErrorMessage}
      />
    </div>
  );
}

export default ImpactsForm;
