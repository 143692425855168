import React, { ReactNode, FC } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./styles.module.scss";

export enum ButtonStyle {
  Primary = "primary",
  Secondary  = "secondary",
  None = "none",
  Full = "full",
  Underline = "underline",
  Icon = 'icon',
  SkinnyPrimary = 'skinnyPrimary',
  SkinnySecondary = 'skinnySecondary',
  PathOption = 'pathOption'
}

interface IButtonProps {
  children?: ReactNode | string;
  style?: ButtonStyle;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  key?: string;
  type?: "submit" | "button" | "reset" | undefined;
  loading?: boolean;
}

interface LinkButtonProps extends IButtonProps {
  to: string;
}

export const LinkButton: FC<LinkButtonProps> = ({
  to,
  style = ButtonStyle.Primary,
  children,
  className,
}) => {
  return (
    <Link
      to={to}
      className={classNames(
        styles.button,
        styles.linkButton,
        style && styles[style],
        className
      )}
    >
      {children}
    </Link>
  );
};

export const Button: FC<IButtonProps> = ({
  style = ButtonStyle.Primary,
  children,
  className,
  loading = false,
  onClick = () => {},
  disabled = false,
  key = "",
  type = "submit",
}) => {
  if (style === "none") {
    return (
      <div
        className={className}
        tabIndex={0}
        role="button"
        {...{
          disabled,
          onClick,
          onKeyPress: onClick,
          key,
          type,
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <button
      className={classNames(styles.button, style && styles[style], className)}
      {...{
        disabled,
        onClick,
        type,
      }}
    >
       {loading ? <div className={styles.loadingContainer}>
        <div className={styles.loading} />
      </div> : children}
    </button>
  );
};
