import React, { FC, ReactElement } from "react";
import { loginUser, userIsAuthorized } from "actions/userActions";
import {
  useSetAppState,
  useAppState,
  useMergeAppState,
  useSetUserState,
} from "context";
import { Formik, Form, FormikHelpers } from "formik";
import { withRouter, useHistory } from "react-router-dom";
import { Button } from "components/Buttons";
import { TextField } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import { invalidEmail } from "lib/commonFunctions";
import { RoutePaths } from "App/routing";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { captchaSiteKey } from "../../../lib/config";
import { ButtonStyle } from "components/Buttons/Button";
import {
  LoginFormErrors,
  LoginFormFields,
  LoginUserData,
  LoginUserResult,
} from "types";

function LoginForm(): ReactElement {
  const appState = useAppState();
  const history = useHistory();

  // Move to Hook When hook is completed
  const useOnLogin = () => {
    const setUserState = useSetUserState();
    const setAppState = useSetAppState();
    const mergeAppState = useMergeAppState();

    const onSubmit = async ({
      email,
      password,
      captchaToken,
    }: LoginUserData) => {
      mergeAppState({ Authenticating: true });

      try {
        const results: LoginUserResult = await loginUser({
          email,
          password,
          captchaToken,
        });

        if (results?.data) {
          const { email, firstName, lastName } = results.data;
          setUserState({ email, firstName, lastName });
        }

        setAppState({
          Authenticated: userIsAuthorized(),
          Authenticating: false,
        });

        return results;
      } catch (err) {
        setAppState({ Authenticated: false, Authenticating: false });
      }
    };

    return onSubmit;
  };


  const initialValues = {
    email: "",
    password: "",
    captchaToken: "",
  };

  const validate = (values: LoginFormFields) => {
    const errors: LoginFormErrors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (invalidEmail(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Less than 8 characters";
    }

    return errors;
  };

  const onLogin = useOnLogin();

  const onSubmit = async (
    values: LoginFormFields,
    { setSubmitting }: FormikHelpers<LoginFormFields>
  ) => {
    if (!appState.Authenticated) {
      setSubmitting(true);
      try {
        await onLogin(values);
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
      }
    }
    history.push(RoutePaths.Home);
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className={styles.loginForm}>
          <TextField
            type="email"
            name="email"
            placeholder="Email"
            label="email"
            className={styles.field}
            labelClassName={styles.labels}
          />
          <TextField
            type="password"
            name="password"
            placeholder="Password"
            label="password"
            className={styles.field}
            labelClassName={styles.labels}
          />
          <div className={styles.signInButtons}>
            <Button
              disabled={isSubmitting}
              className={styles.submitButton}
              type="submit"
            >
              Submit
            </Button>
          </div>
          <div className={styles.forgotPasswordDiv}>
            <button
              className={styles.forgotPasswordButton}
              onClick={() => history.push(RoutePaths.ResetPassword)}
            >
              Forgot password?
            </button>
          </div>
          {captchaSiteKey && (
            <HCaptcha
              sitekey={captchaSiteKey}
              onVerify={(token) => {
                setFieldValue("captchaToken", token);
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
