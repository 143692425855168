import React, { ReactElement, useEffect, useState } from "react";
import { ReactComponent as LogoSvg } from "assets/svgs/Logo.svg";
import styles from "./styles.module.scss";
import { logoutUser, userIsAuthorized } from "actions/userActions";
import { useAppState, useSetAppState } from "context";
import {
  apiHealthCheck,
  databaseHealthCheck,
} from "actions/lib/siteHealthCheckActions";
import { PanTreeLogo } from "components/Images";
import { RoutePaths } from "App/routing";
import { useHistory } from "react-router-dom";
import { RiLogoutBoxLine } from "@react-icons/all-files/ri/RiLogoutBoxLine";
import { RiLoginBoxLine } from "@react-icons/all-files/ri/RiLoginBoxLine";
import { HiHome } from "@react-icons/all-files/hi/HiHome";
import HeaderButton from "./components";
// Header for all pages
// Dumb component, so use stateless Function declaration
// no props

export type HeaderButtonInformation = {
  id: number;
  textTag: string;
  defaultText: string;
  functionality: RoutePaths;
  icon?: ReactElement;
  viewWhenSignedIn: boolean;
  style: number;
};

const Header = () => {
  var connectApi = "Disconnected";
  var connectData = "Disconnected";

  const PathNames: Array<HeaderButtonInformation> = [
    {
      id: 0,
      textTag: "home",
      defaultText: "Home",
      functionality: RoutePaths.Home,
      icon: <HiHome />,
      viewWhenSignedIn: true,
      style: 1
    },
    {
      id: 1,
      textTag: "newOrder",
      defaultText: "Add New Data",
      functionality: RoutePaths.AddNewOrder,
      viewWhenSignedIn: true,
      style: 1
    },
    {
      id: 2,
      textTag: "impacts",
      defaultText: "Impacts",
      functionality: RoutePaths.Impacts,
      viewWhenSignedIn: true,
      style: 1
    },
    {
      id: 3,
      textTag: "Tree Products",
      defaultText: "Tree Products",
      functionality: RoutePaths.TreeProducts,
      viewWhenSignedIn: true,
      style: 1
    },
    {
      id: 4,
      textTag: "manageReports",
      defaultText: "Manage Client",
      functionality: RoutePaths.ManageClient,
      viewWhenSignedIn: true,
      style: 1
    },
    {
      id: 5,
      textTag: "spendTracker",
      defaultText: "Spend Tracker",
      functionality: RoutePaths.SpendTracker,
      viewWhenSignedIn: true,
      style: 2
    },
    {
      id: 6,
      textTag: "creditMemos",
      defaultText: "Statement Of Account",
      functionality: RoutePaths.CreditMemos,
      viewWhenSignedIn: true,
      style: 2
    },
    {
      id: 7,
      textTag: "manageUsers",
      defaultText: "Manage Users",
      functionality: RoutePaths.ManageUser,
      viewWhenSignedIn: true,
      style: 1
    },
    {
      id: 8,
      textTag: "changePassword",
      defaultText: "Change Password",
      functionality: RoutePaths.ResetPassword,
      viewWhenSignedIn: true,
      style: 1
    },
  ];

  const history = useHistory();

  const [connectedToApi, setConnectedToApi] = useState(false);
  const [connectedToDatabase, setConnectedToDatabase] = useState(false);



  const appState = useAppState();
  const setAppState = useSetAppState();

  const onPathClick = (routePath: RoutePaths) => {
    history.push(routePath);
  };

  const onLogout = async () => {
    await logoutUser();

    setAppState({ Authenticated: false, Authenticating: false });
    history.push(RoutePaths.Login);
  };


  return (
    <header>
      <div className={styles.headerLogo}>
        <PanTreeLogo/>
      </div>
      {PathNames.map((path) => {
        return (
          path.viewWhenSignedIn === userIsAuthorized() && (
            <HeaderButton
              key={path.textTag}
              textTag={path.textTag}
              defaultText={path.defaultText}
              icon={path.icon}
              onClick={() => onPathClick(path.functionality)}
              style={path.style}
            />
          )
        );
      })}
      <div className={styles.bottomSpacer}>
        {userIsAuthorized() ? (
          <HeaderButton
            textTag={"logout"}
            defaultText={"Logout"}
            icon={<RiLogoutBoxLine />}
            onClick={() => onLogout()}
            style={1}
          />
        ) : (
          <HeaderButton
            textTag={"login"}
            defaultText={"Login"}
            icon={<RiLoginBoxLine />}
            onClick={() => onPathClick(RoutePaths.Login)}
            style={1}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
