import React, { ReactElement } from "react";
import Layout from "Layout";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "App/routing";
import HomeDetails from "./HomeDetails";
import styles from "./styles.module.scss";

function Home(): ReactElement {
  const history = useHistory();
  const moveToImpacts = () => {
    history.push(RoutePaths.Impacts);
  };
  return (
    <Layout>
      <div className={styles.homePage}>
        <h1 className={styles.elegantShadow}>Home</h1>
        <div className={styles.barContainer}>
          <div className={styles.bar} />
        </div>
        <HomeDetails />
      </div>
    </Layout>
  );
}

export default Home;
