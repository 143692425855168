import Layout from "Layout";
import { ReactElement } from "react";
import ClientForm from "./ClientForm";
import styles from "./styles.module.scss";


function ManageClient(): ReactElement {
  return (
    <Layout>
      <div className={styles.manageClientPageInfo}>
        <h1>Manage Client</h1>
        <div className={styles.barContainer}>
          <div className={styles.bar} />
        </div>
        <div className={styles.manageClientForm}>
          <ClientForm />
        </div>
      </div>
    </Layout>
  );
};

export default ManageClient;