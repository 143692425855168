import React, { ReactElement, useEffect, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "components/Buttons";
import { TextField } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import { RoutePaths } from "App/routing";
import { ResetPasswordErrors, ResetPassword } from "types";
import useUserHook from "hooks/useUsersHook";
import AlertModal from "components/AlertModal";
import {userIsAuthorized, resetPassword, changePassword } from "actions/userActions";

export enum resetPasswordStages {
  SubmitEmail = "submitEmail",
  EmailSent = "emailSent",
  changePasswordForm = "changePasswordForm",
  ResetPasswordForm = "resetPasswordForm",
}

function ResetPasswordForm(): ReactElement {
  const history = useHistory();

  const { passwordValidation, emailValidation, resetPasswordEmail, userErrorMessage} = useUserHook();

  const [ResetPasswordError, setResetPasswordError] = useState<string | null>(null);
  const [alertModalOpen, setAlertModalOpen] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>();
  const [alertMessage, setAlertMessage] = useState<string>();
  const [passwordStage, setPasswordStage] = useState<resetPasswordStages>(resetPasswordStages.SubmitEmail);

  const token = new URLSearchParams(useLocation().search).get("token");
  const initialValues: ResetPassword = {
    email: "",
    oldPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  };

  useEffect(() => {
    if (token && token.length > 0) {
      setPasswordStage(resetPasswordStages.ResetPasswordForm);
    }
    if (userIsAuthorized()) {
      setPasswordStage(resetPasswordStages.changePasswordForm);
    }
  }, []);

  const clearAlert = () => {
    setAlertModalOpen(false);
  };

  const validate = (values: ResetPassword) => {

    if (values.email) return emailValidation(values.email);

    if ( passwordStage == resetPasswordStages.changePasswordForm &&  !values.oldPassword ) {
      return { password: "Please provide your password" };
    }
    if (!values.newPassword) {
      return { password: "Please provide a password" };
    }
    if (!values.passwordConfirmation) {
      return { password: "Please confirm you password" };
    }
    return passwordValidation(values.newPassword, values.passwordConfirmation);
  };

  const onSubmit = async (values: ResetPassword, { setSubmitting }: FormikHelpers<ResetPassword>) => {
    setResetPasswordError(null);
    setSubmitting(true);
    try {
      if (passwordStage === resetPasswordStages.SubmitEmail) {
        if (values.email) {
          await resetPasswordEmail({ email: values.email });
          setAlertTitle("Email Sent");
          setAlertMessage("Check your email for your reset link");
          setAlertModalOpen(true);
        }
      } else if (passwordStage === resetPasswordStages.EmailSent) {
        history.push(RoutePaths.Login);
      } else if (passwordStage === resetPasswordStages.changePasswordForm) {
        await changePassword(values);
        history.push(RoutePaths.Login);
      } else {
        if (!token) {
          throw new Error("Auth Token Empty");
        }
        values.token = token;
        await resetPassword(values);
        history.push(RoutePaths.Login);
      }
      setSubmitting(false);
    } catch (err) {
      console.log(err);
      setSubmitting(false);
      if (err) {
        if (err.original.response.status == 500) setAlertTitle("Invalid email");
        setAlertMessage("Your email does not exist or is invalid ");
        setAlertModalOpen(true);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form name="passwordReset" placeholder="passwordReset">
            {passwordStage === resetPasswordStages.SubmitEmail && (
              <div className={styles.row}>
                <TextField
                  type="email"
                  name="email"
                  placeholder="Please Enter your Email"
                  label="Email"
                  className={styles.field}
                  labelClassName={styles.labels}
                />
              </div>
            )}
            {passwordStage === resetPasswordStages.changePasswordForm && (
              <div className={styles.row}>
                <TextField
                  type="password"
                  name="oldPassword"
                  placeholder="Old Password"
                  label="Old Password"
                  className={styles.field}
                  labelClassName={styles.labels}
                />
              </div>
            )}
            {(passwordStage === resetPasswordStages.ResetPasswordForm ||
              passwordStage === resetPasswordStages.changePasswordForm) && (
              <div>
                <div className={styles.row}>
                  <TextField
                    type="password"
                    name="newPassword"
                    placeholder="New Password"
                    label="New Password"
                    className={styles.field}
                    labelClassName={styles.labels}
                  />
                </div>
                <div className={styles.row}>
                  <TextField
                    type="password"
                    name="passwordConfirmation"
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    className={styles.field}
                    labelClassName={styles.labels}
                  />
                </div>
              </div>
            )}
            {passwordStage === resetPasswordStages.EmailSent && (
              <div> Check your email for the reset link </div>
            )}
            <Button
              disabled={isSubmitting}
              className={styles.submitButton}
              type="submit"
            >
              {passwordStage === resetPasswordStages.EmailSent
                ? "Return"
                : "Submit"}
            </Button>
            {ResetPasswordError && <div>{ResetPasswordError}</div>}
          </Form>
        )}
      </Formik>
      <AlertModal
        open={alertModalOpen}
        onClose={clearAlert}
        title={alertTitle}
        message={alertMessage}
      />
    </>
  );
}

export default ResetPasswordForm;
