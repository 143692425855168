import React, { useState } from 'react'
import { CreditMemosApi } from 'api';
import {Order, CreateOrder, getOrdersFilters, CreateCreditMemo, CreditMemo } from 'types';
import { getAPIErrorMessage } from 'lib/errorHandling';


interface CreditMemoInfo {
  creditMemosErrorMessage: string | undefined;
  ordersAndMemos: Array<any>;
  clearError: () => void;
  create: (order:CreateCreditMemo) => void;
  fetchOrdersAndCreditMemos: (clientId: number, siteId: number) => void;
  deleteMemo: (id: number) => void;
  clearData: () => void;
}

function useCreditMemoHook(): CreditMemoInfo {
  
  const [creditMemosErrorMessage, setCreditMemosErrorMessage] = useState<string>();
  const [ordersAndMemos, setOrdersAndMemos] = useState<Array<CreditMemo>>([])

  const fetchOrdersAndCreditMemos = async (clientId: number, siteId: number) => {
    const data = await CreditMemosApi.fetchCreditMemos(clientId, siteId)
    setOrdersAndMemos(data)
  }

  const setError = (err: any) => {
    const message = getAPIErrorMessage(err);
    setCreditMemosErrorMessage(message)
  }

  const clearError = () => {
    setCreditMemosErrorMessage(undefined);
  }

  const clearData = () =>{
    setOrdersAndMemos([])
  }

  const create = async (memo:CreateCreditMemo) => {
    try {
      await CreditMemosApi.create(memo);
    } catch (err) {
      setError(err)
      throw err;
    }
  }

  const deleteMemo = async (id: number) => {
    try {
      await CreditMemosApi.deleteMemo(id);
    } catch (err) {
      setError(err)
      throw err;
    }
    
  }

  return {
    creditMemosErrorMessage,
    ordersAndMemos,
    create,
    clearError,
    fetchOrdersAndCreditMemos,
    deleteMemo,
    clearData,
  }
}


export default useCreditMemoHook;