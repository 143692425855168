import { ImpactsApi } from 'api';
import React, { useState } from 'react';
import { CreateImpactsData, GetImpactsParams, Impact, ImpactSummary } from 'types';
import { getAPIErrorMessage } from 'lib/errorHandling';


interface ImpactsHookInfo {
  fetchImpacts: (data: GetImpactsParams) => void;
  fetchImpact: (data: GetImpactsParams) => void;
  fetchTotalImpactsCount: (data: GetImpactsParams) => void;
  resetImpactError: () => void;
  impactErrorMessage: string;
  impacts: Array<Impact>, 
  impact?: Impact;
  totalCount: number;
}

function useImpactsHook(): ImpactsHookInfo {

  const [impacts,setImpacts] = useState<Array<Impact>>([])
  const [impact, setImpact] = useState<Impact>()
  const [totalCount, setTotalCount] = useState<number>(0);
  const [impactErrorMessage, setImpactErrorMessage] = useState<string>('')

  const setError = (err: any) => {
    const message = getAPIErrorMessage(err);
    setImpactErrorMessage(message)
  }


  const fetchImpacts = async (data: GetImpactsParams) => {
    try {
      const impacts = await ImpactsApi.fetchImpacts(data);
      setImpacts(impacts);
    } catch (error) {
      setError(error)
    }
   
  }


  const fetchImpact = async ({clientId, maxDate, minDate}: GetImpactsParams) => {
    try {
      setImpact(undefined)
      const impact = await ImpactsApi.fetchImpact({clientId, maxDate, minDate});
      setImpact(impact)
    } catch (error) {
      setError(error)
    }

  }

  const fetchTotalImpactsCount = async (data: GetImpactsParams) => {
    try {
      const count = await ImpactsApi.fetchTotalImpactsCount(data);
      setTotalCount(count);
    } catch (error) {
      setError(error)
    }

  }

  const resetImpactError = () => {
    setImpactErrorMessage('');
  }


  return {
    fetchTotalImpactsCount,
    fetchImpacts,
    fetchImpact,
    resetImpactError,
    impactErrorMessage,
    totalCount,
    impacts,
    impact,
  }

}

export default useImpactsHook;