import React, { ReactElement } from "react";
import Layout from "Layout";
import TreeProductsForm from "./TreeProductsForm";
import styles from "./styles.module.scss";

function TreeProducts(): ReactElement {
  return (
    <Layout>
      <div className={styles.TreeProductsForm}>
        <h1>Tree Products</h1>
        <div className={styles.barContainer}>
          <div className={styles.bar} />
        </div>
        <div className={styles.TreeProductsForm}>
          <TreeProductsForm />
        </div>
      </div>
    </Layout>
  );
}

export default TreeProducts;
