import useOrdersHook from "hooks/useOrdersHook";
import React, { ReactElement, useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from "chart.js";
import { Bar } from "react-chartjs-2";
import Layout from "Layout";
import styles from "./styles.module.scss";
import Select from "react-select";
import { Client, CSVHeader, SpendTrackerBar } from "types";
import ClientAutocomplete from "components/ClientAutocomplete";
import useClientsHook from "hooks/useClientsHook";
import useDebounceHook from "hooks/useDebounceHook";
import CSVDownload from "components/CSVDownload";
import moment from "moment";
import { DateFormat, formatDate, formatDateLabel } from "lib/commonFunctions";
import AlertModal from "components/AlertModal";
import MultiplePresetDatePicker from "components/MultiplePresetDatePicker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface OrderInterval {
  label: string;
  value: string;
}

enum Intervals {
  PerOrder = "perOrder",
  Monthly = "monthly",
  Yearly = "yearly",
}

const intervalOptions = [
  { label: "Per Order", value: Intervals.PerOrder },
  { label: "Monthly", value: Intervals.Monthly },
  { label: "Yearly", value: Intervals.Yearly },
];

function SpendTracker({}): ReactElement {
  const [graphInfo, setGraphInfo] = useState<any>({
    labels: ["subtotal"],
    datasets: [100],
  });

  const [selectedClientSearch, setSelectedClientSearch] = useState<Client>();
  const [orderInterval, setOrderInterval] = useState<OrderInterval>({
    label: "Per Order",
    value: Intervals.PerOrder,
  });
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);
  const [savedOrders, setSavedOrders] = useState<Array<SpendTrackerBar>>([]);
  const maxClientsInDropDown = 10;
  const [csvFileName, setCSVFileName] = useState<string>('download.csv');
  const [csvHeaders, setCSVHeaders] = useState<Array<CSVHeader>>([]);

  const {
    clients,
    fetchClients,
    setSearchValue,
    fetchTotalClientCount,
    searchValue,
  } = useClientsHook();

  const { getOrders, clearError, orders, orderErrorMessage } = useOrdersHook();

  const searchDebounce = useDebounceHook(searchValue);

  const orderIntervalChange = (e: any) => {
    setOrderInterval(e);
  };

  const menuToggle = () => {
    setFilterMenuOpen(!filterMenuOpen);
  };

  function filterOrdersByInterval() {
    if (orderInterval?.value == Intervals.PerOrder || !orderInterval?.value) {
      setSavedOrders([...orders]);
      return;
    }
    let newOrders: Array<SpendTrackerBar> = [];
    orders.forEach((order) => {
      let index = newOrders.findIndex((newOrder) =>
        moment(newOrder.date).isSame(
          order.date,
          orderInterval?.value == Intervals.Yearly ? "year" : "month"
        )
      );
      if (index == -1) {
        newOrders.push(order);
      } else {
        newOrders[index] = joinBar(newOrders[index], order);
      }
    });
    setSavedOrders(newOrders);
  }

  const joinBar = (bar1: SpendTrackerBar, bar2: SpendTrackerBar) => {
    return {
      beverages: bar1.beverages + bar2.beverages,
      catering: bar1.catering + bar2.catering,
      coffeeAndTeas: bar1.coffeeAndTeas + bar2.coffeeAndTeas,
      dairy: bar1.dairy + bar2.dairy,
      date: bar1.date,
      food: bar1.food + bar2.food,
      monthlyFees: bar1.monthlyFees + bar2.monthlyFees,
      other: bar1.other + bar2.other,
      snacks: bar1.snacks + bar2.snacks,
      supplies: bar1.supplies + bar2.supplies,
      tax: bar1.tax + bar2.tax,
    };
  };

  const options = {
    plugins: {
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
      legend: {
        position: "left" as const,
        align: "center" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "circle" as const,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
        label: "Order Date",
      },
      y: {
        stacked: true,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
  };

  const buildCSV = async () => {
    if(!selectedClientSearch || orders.length < 1){
      return;
    }
    let CSVTitle = selectedClientSearch?.clientName;
    let fName = CSVTitle;
    if(minDate){
      fName += formatDate(minDate)?.replace(/\s/g, "_");
    }
    if(maxDate){
      if(minDate){ fName += "-"}
      fName += formatDate(maxDate)?.replace(/\s/g, "_");
    }
    const headerKeys = Object.keys(orders[1]).map(key => {
      return {
        key:key,
        label:key
      }
    });

    setCSVFileName(fName)
    setCSVHeaders(headerKeys)
  };

  useEffect(() => {
    const startingRow = (currentPage - 1) * maxClientsInDropDown;
    fetchClients({
      limit: maxClientsInDropDown,
      from: startingRow,
      searchFilter: searchDebounce,
    });
  }, [currentPage]);

  useEffect(() => {
    setLoading(false);
    filterOrdersByInterval();
    buildCSV()
  }, [orders, orderInterval]);

  useEffect(() => {
    if (!selectedClientSearch?.id) {
      return;
    }
    const newFilters = {
      minDate: minDate,
      maxDate: maxDate,
      clientId: selectedClientSearch?.id,
    };
    getOrders(newFilters);
    setLoading(true);
  }, [selectedClientSearch, minDate, maxDate]);

  useEffect(() => {
    fetchTotalClientCount(searchDebounce);
    setCurrentPage(1);
    fetchClients({ limit: maxClientsInDropDown, searchFilter: searchDebounce });
  }, [searchDebounce]);

  useEffect(() => {
    let labels = savedOrders.map(function (order) {
      if (orderInterval?.value == Intervals.Yearly) {
        return formatDateLabel(order.date, DateFormat.YEAR);
      } else if (orderInterval?.value == Intervals.Monthly) {
        return formatDateLabel(order.date, DateFormat.MONTH_YEAR);
      } else {
        return formatDateLabel(order.date, DateFormat.MONTH_DAY_YEAR);
      }
    });

    setGraphInfo({
      labels,
      type: 'bar',
      datasets: [
        /*
        {
          label: "Associated Tax",
          type: "bar",
          data: savedOrders.map((order) => order.tax),
          backgroundColor: "#000000",
          pointStyle: "circle",
        },
        */
        {
          label: "Coffee & Tea",
          type: "bar",
          data: savedOrders.map((order) => order.coffeeAndTeas),
          backgroundColor: "#3e95cd",
          pointStyle: "circle",
        },
        {
          label: "Dairy",
          type: "bar",
          data: savedOrders.map((order) => order.dairy),
          backgroundColor: "#8e5ea2",
          pointStyle: "circle",
        },
        {
          label: "Beverage",
          type: "bar",
          data: savedOrders.map((order) => order.beverages),
          backgroundColor: "#3cba9f",
          pointStyle: "circle",
        },
        {
          label: "Snacks",
          type: "bar",
          data: savedOrders.map((order) => order.snacks),
          backgroundColor: "#317D6F",
          pointStyle: "circle",
        },
        {
          label: "Supplies",
          data: savedOrders.map((order) => order.supplies),
          backgroundColor: "#afc3db",
          pointStyle: "circle",
        },
        {
          label: "Catering",
          type: "bar",
          data: savedOrders.map((order) => order.catering),
          backgroundColor: "#ACC15B",
          pointStyle: "circle",
        },
        {
          label: "Other",
          type: "bar",
          data: savedOrders.map((order) => order.other),
          backgroundColor: "#5bc251",
          pointStyle: "circle",
        },
        {
          label: "Monthly Fees",
          type: "bar",
          data: savedOrders.map((order) => order.monthlyFees),
          backgroundColor: "#4C308A",
          pointStyle: "circle",
        },
      ],
    });
  }, [savedOrders]);

  return (
    <Layout>
      <div className={styles.SpendTracker}>
        <h1 className={styles.elegantShadow}>Spend Tracker</h1>
        <div className={styles.barContainer}>
          <div className={styles.bar} />
        </div>
        <div>
          <div className={styles.clientTitle}>
            {selectedClientSearch?.clientName}
          </div>
          <div className={styles.headerSection}>
            <div className={styles.headerFields}>
              <div className={styles.ClientSelect}>
                <div className={styles.fieldLabel}> Client </div>
                <ClientAutocomplete
                  updateSelectedClient={setSelectedClientSearch}
                  clientList={clients}
                  setValue={setSearchValue}
                  value={searchValue}
                  disabled={loading}
                />
              </div>
              <div className={styles.selectDiv}>
                <div className={styles.fieldLabel}> Order interval </div>
                <Select
                  className={styles.selectContainer}
                  aria-labelledby="dropdownMenuLink"
                  isSearchable={false}
                  options={intervalOptions}
                  placeholder={"Select an order interval"}
                  onChange={orderIntervalChange}
                  value={orderInterval}
                  isDisabled={loading}
                />
              </div>
            </div>
            <div>
              <MultiplePresetDatePicker
                maxDate={maxDate}
                minDate={minDate}
                setMaxDate={setMaxDate}
                setMinDate={setMinDate}
              />
            </div>
          </div>

          <div className={loading ? styles.loadingScreen : styles.tableSection}>
            <div>
              <Bar data={graphInfo} options={options} />
            </div>
            {loading && (
              <div className={styles.spinnerContainer}>
                <div className={styles.loadingSpinner}></div>
              </div>
            )}
          </div>

          {savedOrders.length == 0 && (
            <div className={styles.noData}>No Data</div>
          )}
          <AlertModal
            open={!!orderErrorMessage}
            onClose={clearError}
            title={"Error"}
            message={orderErrorMessage}
          />
          {
            //<div className={styles.downloadCSV}>
            //<CSVDownload values={orders} headers={csvHeaders} title='Download CSV' filename={csvFileName} />
            //</div>
          }
        </div>
      </div>
    </Layout>
  );
}

export default SpendTracker;
