import moment from "moment";

export enum DateFormat {
  SQL_DATE = 'YYYY-MM-DD',
  SQL_DATE_TIME = 'YYYY-MM-DD HH:mm',
  DATE_ONLY_SHORT = 'DD MMM YYYY',
  DATE_TIME_SHORT = 'DD MMM YYYY HH:mm',
  MONTH_DAY_YEAR = 'MMM Do, YYYY',
  MONTH_YEAR = 'MMMM YYYY',
  YEAR = 'YYYY'

}

export const invalidEmail = (email: string) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const camelCaseToTitle = (text: string): string => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const formatISODateOnly = (date: Date | undefined) => {
  if (!date) return
  return date.toISOString().substring(0, 10);
}

export const todaysDate = new Date();
export const todaysDateString = () => moment().format(DateFormat.SQL_DATE_TIME)

export const addMonthsFromCurrentDate = function (months: number) {
  var date = new Date();
  var newDate = new Date(date.setMonth(date.getMonth() + months));
  return newDate;
};

export const firstDayOfTheYear = function () {
  return new Date(new Date().getFullYear(), 0, 1);
};

export const addDaysFromCurrentDate = function (days: number) {
  var date = new Date();
  var newDate = date.setDate(date.getDate() + days);
  return new Date(newDate);
};

export const formatOneDecimal = (value?: number) => {
  if (!value) return
  return (Math.round(value* 100) / 100).toFixed(1);
}

export const sqlDateOnlyFormat = (date: Date) => {
  if (!date) return
  return moment(date).format(DateFormat.SQL_DATE);
}

export const formatDate = (date: Date | string, format: DateFormat = DateFormat.DATE_ONLY_SHORT) => {
  if (!date) return
  return moment(date).format(format)
}

export const getDate = (value?: string) => {
  if (!value) return;
  const dateMoment = moment(value)
  const date = new Date();
  date.setDate(dateMoment.date());
  date.setMonth(dateMoment.month());
  date.setFullYear(dateMoment.year());
  return date
}

export const formatDateLabel = (date: string, dateFormat: DateFormat) => {
  return moment(date).format(dateFormat)
}
