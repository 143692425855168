import React, { ReactElement, ReactNode } from "react";
import styles from "./styles.module.scss";

interface BaseModalProps {
  children?: ReactNode;
}

function BaseModal({ children }: BaseModalProps): ReactElement {
  return(  
    <div className={styles.backGround}>
        {children}
    </div>);
}

export default BaseModal;
