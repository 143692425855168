import React, { ReactElement } from "react";
import Layout from "Layout";
import ClientReportsForm from "./ClientReportsForm";
import styles from "./styles.module.scss";


function ClientReports(): ReactElement {
  return (
    <Layout>
      <div className={styles.clientReportsPage}>
        <h1 >Client Report</h1>
        <div className={styles.barContainer}>
          <div className={styles.bar} />
        </div>
        <div className={styles.clientReportForm}>
          <ClientReportsForm />
        </div>
      </div>
    </Layout>
  );
};


export default ClientReports;
