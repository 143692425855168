import React, { useEffect, useState } from 'react';
import { ShopifyApi } from 'api';
import { getAPIErrorMessage } from 'lib/errorHandling';
import { ShopifyClient, ShopifyProduct } from 'types';


interface ShopifyHookInfo {
  findShopifyClient: (email: string) => Promise<ShopifyClient | undefined>;
  findShopifyProduct: (productId: number) => Promise<ShopifyProduct | undefined>;
  resetShopifyError: () => void;
  shopifyClient?: ShopifyClient;
  shopifyErrorMessage: string;
}

function useShopifyHook(): ShopifyHookInfo {

  const [shopifyClient, setShopifyClient] = useState<ShopifyClient>()
  const [shopifyErrorMessage, setShopifyErrorMessage] = useState<string>('');

  const setError = (err: any) => {
    const message = getAPIErrorMessage(err);
    setShopifyErrorMessage(message)
  }

  const findShopifyClient = async (email: string): Promise<ShopifyClient | undefined> => {

    try {
      return await ShopifyApi.findClient(email);
    } catch (err) {
      setError(err)
    }

    return
  }
  const findShopifyProduct = async (productId: number): Promise<ShopifyProduct | undefined> => {

    try {
      return await ShopifyApi.findProduct(productId);
    } catch (err) {
      setError(err)
    }

    return
    
    
  }

  const resetShopifyError = () => {
    setShopifyErrorMessage('');
  }
  
  return {
    findShopifyClient,
    findShopifyProduct,
    resetShopifyError,
    shopifyClient,
    shopifyErrorMessage,
  }
}

export default useShopifyHook;