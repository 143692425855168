import { typingDebounce } from 'lib/config';
import React, { useEffect, useState } from 'react';



function useDebounceHook(value: any, delay: number = typingDebounce) {

  const [debounceValue, setDebounceValue] = useState<any>(value);

  useEffect(() => {

    const timer = setTimeout(() => setDebounceValue(value), delay)
    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debounceValue;

}

export default useDebounceHook;