import TextField from "components/Forms/TextField";
import React, { ReactElement, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IconContext } from "@react-icons/all-files";
import { BsCheck } from "@react-icons/all-files/bs/BsCheck";
import { Button } from "components/Buttons";
import { ButtonStyle } from "components/Buttons/Button";
import useShopifyHook from "hooks/useShopifyHook";
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import { FcCancel } from "@react-icons/all-files/fc/FcCancel";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import { FaUnlink } from "@react-icons/all-files/fa/FaUnlink";
import classNames from "classnames";
import { ClientEmail, ShopifyClient } from "types";

interface EmailProps {
  index: number;
  email?: ClientEmail;
  hasShopifyConnection?: boolean;
  allowDeletion: boolean;
  setSelectedEmail?: () => void;
  setFieldValue: (id: string, value: number | undefined) => void;
  removeItem: () => void;
  setClientName: (clientName?: string) => void;
  setPantreeCheckBox: (pantree: boolean) => void;
  setFoodServiceCheckBox: (foodService: boolean) => void;

  findShopifyClient: (email: string) => Promise<ShopifyClient | undefined>;
}

function Email({
  index,
  email,
  hasShopifyConnection,
  allowDeletion,
  setSelectedEmail,
  removeItem,
  setFieldValue,
  setClientName,
  setPantreeCheckBox,
  setFoodServiceCheckBox,
  findShopifyClient,
}: EmailProps): ReactElement {
  const [connectionTried, setConnectionTried] = useState<boolean>(false);


  const findShopifyConnection = async () => {
    setConnectionTried(false)
    if (!email?.email) return
    const shopifyClient = await findShopifyClient(email.email)
    setConnectionTried(true)
    setClientName(shopifyClient?.company || shopifyClient?.fullName)
    setFieldValue(`emails[${index}].pantreeId`, shopifyClient?.pantreeId)
    setFieldValue(`emails[${index}].foodServiceId`, shopifyClient?.foodServiceId)
  }

  const getConnectionStatusIcon = () => {
    if (hasShopifyConnection) {
      return (
        <IconContext.Provider value={{ size: "1.5em" }}>
          <BsCheck color="green" />
        </IconContext.Provider>
      );
    }
    if (connectionTried) {
      return (
        <IconContext.Provider value={{ size: "1.5em" }}>
          <FcCancel color="red" />
        </IconContext.Provider>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.deleteSection}>
        {allowDeletion && (
          <div className={styles.delete}>
            <Button
              style={ButtonStyle.Underline}
              className={styles.connectBtn}
              onClick={removeItem}
              type="button"
            >
              <IconContext.Provider value={{ size: "1em", color: "red" }}>
                <AiOutlineDelete />
              </IconContext.Provider>
            </Button>
          </div>
        )}
      </div>
      <div className={styles.textField}>
        <TextField
          type="email"
          name={`emails[${index}].email`}
          labelClassName={styles.labels}
          required
          disabled={hasShopifyConnection}
        />
      </div>

      <div className={styles.connection}>
        {!email?.id &&(hasShopifyConnection ? (
          <Button
            style={ButtonStyle.Underline}
            className={styles.connectBtn}
            onClick={() =>
              setFieldValue(`emails[${index}].shopifyClientId`, undefined)
            }
            type="button"
          >
            <FaUnlink />
          </Button>
        ) : (
          <Button
            style={ButtonStyle.Underline}
            className={styles.connectBtn}
            onClick={findShopifyConnection}
            type="button"
          >
            <FaLink />
          </Button>
        ))}
        
        <div className={styles.icon}>{getConnectionStatusIcon()}</div>
      </div>
    </div>
  );
}

export default Email;
