import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Formik, Form, FormikHelpers, FieldArray } from "formik";
import { Button } from "components/Buttons";
import { TextField } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import {
  CreateEditClient,
  CreatClientErrors,
  Client,
  ClientEmail,
} from "types";
import BaseModal from "components/BaseModal";
import Email from "./components/Email";
import { ButtonStyle } from "components/Buttons/Button";
import useShopifyHook from "hooks/useShopifyHook";
import AlertModal from "components/AlertModal";
import { IconContext } from "@react-icons/all-files";
import { BsCheck } from "@react-icons/all-files/bs/BsCheck";

interface CreateEditClientModalProps {
  open: boolean;
  onCreateClient(values: CreateEditClient): void;
  onClose: () => void;
  client?: Client;
  onUpdate?: (values: CreateEditClient) => void;
}

function CreateEditClientModal({
  open,
  onCreateClient,
  onClose,
  onUpdate,
  client,
}: CreateEditClientModalProps): ReactElement {
  const initialValues: CreateEditClient = { emails: [{}]  };
  const ref = useRef(null);
  const [signUpError, setCreateClientError] = useState<string | null>(null);
  const [values, setValues] = useState<CreateEditClient>(initialValues);


  const { findShopifyClient, shopifyErrorMessage, resetShopifyError } = useShopifyHook();

  const validate = (values: CreateEditClient) => {
    return true
  };

  const setClientToValues = () => {
    let values = { ...initialValues };
    if (client) {
      values.id = client.id;
      values.emails = client.emails
      values.clientName = client.clientName;
    }
    setValues(values);
  };

  const onSubmit = async (
    values: CreateEditClient,
    { setSubmitting }: FormikHelpers<CreateEditClient>
  ) => {
    setCreateClientError(null);
    setSubmitting(true);
    if (client && onUpdate) {
      let newEmails = values.emails?.map(email => email.email)
      client.emails?.forEach((emailRow: ClientEmail)=>{
        if(!newEmails?.includes(emailRow.email)){
          emailRow.deleted = 1
          values.emails?.push(emailRow)
        }
      })
      await onUpdate(values);
    } else {
      await onCreateClient(values);
    }
    setSubmitting(false);
    onClose();
  };

  const setClientName = (
    values: CreateEditClient,
    setFieldValues: (field: string, value: any) => void,
    name?: string
  ) => {
    if (values.clientName || !name) return;
    setFieldValues("clientName", name);
  };

  const setPantreeCheckBox = ( pantree: boolean, setFieldValues: (field: string, value: any) => void,) => {
    setFieldValues("pantree", pantree)
  };

  const setFoodServiceCheckBox = ( foodService: boolean, setFieldValues: (field: string, value: any) => void,) => {
    setFieldValues("foodService", foodService)
  };

  const checkFoodServiceIds = (emails?: Array<ClientEmail> ) => {
    if (!emails) return false
    return !!emails.find(email => !!email.foodServiceId)
  }

  const checkPantreeIds = (emails?: Array<ClientEmail> ) => {
    if (!emails) return false
   return !!emails.find(email => !!email.pantreeId)
  }


  useEffect(() => {
    setClientToValues();
  }, [client]);

  return (
    <>
      {open && (
        <BaseModal>
          <Formik
            initialValues={values}
            onSubmit={onSubmit}
            enableReinitialize={true}
            innerRef={ref}
          >
            {({ isSubmitting, errors, values, setFieldValue }) => (
              <Form className={styles.modal}>
               
                <div className={styles.emails}>
                  <div className={styles.emailTitle}>Emails</div>
                  <FieldArray name={"emails"}>
                    {({ push, remove, form }) => {
                      return (
                        <div>
                          {form.values.emails?.map(
                            (email: ClientEmail, index: number) => (
                              <Email
                                key={`${index}`}
                                index={index}
                                email={email}
                                hasShopifyConnection={!!email.pantreeId || !!email.foodServiceId}
                                removeItem={() => remove(index)}
                                allowDeletion={form.values.emails.length > 1}
                                setFieldValue={setFieldValue}
                                findShopifyClient={findShopifyClient}
                                setPantreeCheckBox={(isChecked: boolean) => setPantreeCheckBox(isChecked, setFieldValue)}
                                setFoodServiceCheckBox={(isChecked: boolean) => setFoodServiceCheckBox(isChecked, setFieldValue)}
                                setClientName={(name?: string) =>
                                  setClientName(values, setFieldValue, name)
                                }
                              />
                            )
                          )}
                          <div className={styles.addEmail}>
                            <Button
                              disabled={isSubmitting}
                              className={styles.submitButton}
                              type="button"
                              onClick={() => push({})}
                              style={ButtonStyle.Underline}
                            >
                              Add Email
                            </Button>
                          </div>
                        </div>
                      );
                    }}
                  </FieldArray>
                </div>
                <div className={styles.nameField}>
                  <div className={styles.nameLabel}>Name</div>
                  <TextField
                    type="text"
                    name="clientName"
                    required
                  />
                </div>
                {checkPantreeIds(values.emails) && (
                  <div className={styles.siteConfirmation}>
                    <div className={styles.nameLabel}>Pantree</div>
                    <div className={styles.foodServiceBox}>
                      <IconContext.Provider value={{ size: "1.5em" }}>
                        <BsCheck color="green" />
                      </IconContext.Provider>
                    </div>
                  </div>
                )}
                {checkFoodServiceIds(values.emails) && (
                  <div className={styles.siteConfirmation}>
                    <div className={styles.nameLabel}>Food Service</div>
                    <div className={styles.foodServiceBox}>
                      <IconContext.Provider value={{ size: "1.5em" }}>
                        <BsCheck color="green" />
                      </IconContext.Provider>
                    </div>
                  </div>
                )}
               
                <div className={styles.saveCancelBtns}>
                  <Button
                    disabled={isSubmitting}
                    className={styles.submitButton}
                    type="reset"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    className={styles.submitButton}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>

                {signUpError && <div>{signUpError}</div>}
              </Form>
            )}
          </Formik>
          <AlertModal
            open={!!shopifyErrorMessage}
            onClose={resetShopifyError}
            title={"Error"}
            message={shopifyErrorMessage}
          />
        </BaseModal>
      )}
    </>
  );
}

export default CreateEditClientModal;
