import { ReactElement } from "react";

import styles from "./styles.module.scss";
import QuantitySelect from "components/QuantitySelect";

interface OrderRowParams {
  product: string;
  setProduct: (amount: number) => void;
  value?: number;
}

function OrderRow({ product, setProduct, value }: OrderRowParams): ReactElement {
  return (
    <>
      <tr className={styles.striped}>
        <td>{product}</td>
        <td>
          <QuantitySelect
            updateSelectedQuantity={setProduct}
            allowNegatives={true}
            value={value}
          />
        </td>
      </tr>
    </>
  );
}
export default OrderRow;
