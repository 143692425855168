import { ReactElement, useEffect, useRef, useState } from "react";
import { Formik, Form, FormikHelpers, useFormikContext } from "formik";
import { Button } from "components/Buttons";
import { TextField } from "components/Forms/TextField";
import styles from "./styles.module.scss";
import {
  CreateTreeProduct,
  CreateTreeProductErrors,
  ShopifyProduct,
  TreeProduct,
} from "types";
import BaseModal from "components/BaseModal";
import useShopifyHook from "hooks/useShopifyHook";
import { ButtonStyle } from "components/Buttons/Button";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import { FaUnlink } from "@react-icons/all-files/fa/FaUnlink";
import AlertModal from "components/AlertModal";
import useTreeProductHook from "hooks/useTreeProductsHook";

interface CreateFullTreeProductProp {
  treeProduct?: TreeProduct;
  onClose: () => void;
  onSave: () => void;
}

function CreateTreeProductModal({
  treeProduct,
  onClose,
  onSave,
}: CreateFullTreeProductProp): ReactElement {
  const initialValues: TreeProduct = {
    name: "",
    shopifyProductId: 0,
    id: undefined,
    treesPerQuantity: 1,
  };
  const [signUpError, setCreateTreeProductError] = useState<string | null>(
    null
  );
  const [values, setValues] = useState<TreeProduct>(initialValues);
  const [shopifyProduct, setShopifyProduct] = useState<ShopifyProduct>();

  const { findShopifyProduct, shopifyErrorMessage, resetShopifyError } = useShopifyHook();
  const {
    updateTreeProduct,
    createTreeProduct,
    resetTreeProductError,
    treeProductErrorMessage
  } = useTreeProductHook();

  const findShopifyConnection = async (
    values: TreeProduct,
    setFieldValue: (key: string, value: any) => void
  ) => {
    if (!values.shopifyProductId) return;
    const shopifyProduct = await findShopifyProduct(values.shopifyProductId);
    setShopifyProduct(shopifyProduct);
    setFieldValue(`name`, shopifyProduct?.title);
  };
  const setValuesToTreeProduct = () => {
    let values = { ...initialValues };

    if (treeProduct) {
      if (treeProduct.shopifyProductId) setShopifyProduct({id: treeProduct.shopifyProductId, title: treeProduct.name})
      values.id = treeProduct.id;
      values.shopifyProductId = treeProduct.shopifyProductId;
      values.name = treeProduct.name;
      values.treesPerQuantity = treeProduct.treesPerQuantity;
    }
    setValues(values);
  };

  const validate = (values: CreateTreeProduct) => {
    const errors: CreateTreeProductErrors = {};

    if (!values.name) {
      errors.name = "Required";
    } else if (!values.shopifyProductId) {
      errors.shopifyProductId = "Required";
    }
    return errors;
  };

  const onSubmit = async (
    values: TreeProduct,
    { setSubmitting }: FormikHelpers<TreeProduct>
  ) => {
    setCreateTreeProductError(null);
    setSubmitting(true);
    if (treeProduct) {
      await updateTreeProduct(values);
    } else {
      await createTreeProduct(values);
    }
    onSave();
    setSubmitting(false);
    onClose();
  };

  useEffect(() => {
    setValuesToTreeProduct();
  }, [treeProduct]);

  return (
    <Formik
      initialValues={values}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, errors, setFieldValue, values }) => (
        <BaseModal>
          <Form className={styles.modal}>
            <div>{values.name}</div>
            <div className={styles.productRow}>
              <TextField
                type="text"
                name="shopifyProductId"
                label="Shopify Product Id"
                className={styles.field}
                labelClassName={styles.labels}
                disabled={!!shopifyProduct}
              />
              <div className={styles.linkIcons}>
                {shopifyProduct ? (
                  <Button
                    style={ButtonStyle.Underline}
                    className={styles.connectBtn}
                    onClick={() => {
                      setFieldValue(`name`, undefined);
                      setShopifyProduct(undefined);
                    }}
                    type="button"
                  >
                    <FaUnlink />
                  </Button>
                ) : (
                  <Button
                    style={ButtonStyle.Underline}
                    className={styles.connectBtn}
                    onClick={() => findShopifyConnection(values, setFieldValue)}
                    type="button"
                  >
                    <FaLink />
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.startDate}>
              <div className={styles.dateLabel}> Trees Per Quantity </div>
              <div className={styles.treesPerQuantity}>
                <TextField
                  type="text"
                  name="treesPerQuantity"
                  className={styles.field}
                  labelClassName={styles.labels}
                />
              </div>
            </div>

            <div className={styles.buttonSection}>
              <Button
                disabled={isSubmitting || !shopifyProduct}
                className={styles.submitButton}
                type="submit"
              >
                Submit
              </Button>
              <Button
                className={styles.closeButton}
                type="reset"
                onClick={onClose}
              >
                Close
              </Button>
            </div>
            {signUpError && <div>{signUpError}</div>}
          </Form>
          <AlertModal
            open={!!(shopifyErrorMessage || treeProductErrorMessage)}
            onClose={() => {resetShopifyError(); resetTreeProductError();}}
            title={"Error"}
            message={shopifyErrorMessage || treeProductErrorMessage}
          />
        </BaseModal>
      )}
    </Formik>
  );
}

export default CreateTreeProductModal;
