import * as base from "../actions/lib/baseActions";
import { apiEndpoints } from "lib/config";
import { CreateTreeProduct, TreeProduct } from "types";


export const fetchTreeProducts = async (): Promise<Array<TreeProduct>> => {
  const response = await base.get(apiEndpoints.treeProducts);
  return response.data;
};

export const createTreeProduct = async (data: CreateTreeProduct ) => {
  const response = await base.post(apiEndpoints.treeProducts, data);
  return response.data;
};

export const updateTreeProduct = async (data: TreeProduct) => {
  const response = await base.put(`${apiEndpoints.treeProducts}/${data.id}`, data);
  return response.data;
};
