import React, { useState } from 'react'
import { OrdersApi } from 'api';
import {Order, CreateOrder, getOrdersFilters } from 'types';
import { getAPIErrorMessage } from 'lib/errorHandling';


interface OrderHookInfo {
  orderErrorMessage: string | undefined;
  orders: Array<any>;
  plasticsPrice: number | undefined;
  getOrders: (filters: getOrdersFilters) => void;
  getPlasticsPrice: () => void;
  updatePlasticsPrice: (price: number) => Promise<void>;
  clearError: () => void;
  createOrder: (order:CreateOrder) => void;
  clearOrders: () => void
}

function useOrdersHook(): OrderHookInfo {
  
  const [orderErrorMessage, setOrderErrorMessage] = useState<string>();
  const [orders, setOrders] = useState<Array<any>>([])
  const [plasticsPrice, setPlasticsPrice] = useState<number>();

  const setError = (err: any) => {
    const message = getAPIErrorMessage(err);
    setOrderErrorMessage(message)
  }

  const clearError = () => {
    setOrderErrorMessage(undefined);
  }

  const getOrders = async (filters: getOrdersFilters) => {
    try {
      const orders = await OrdersApi.getOrders(filters);
      setOrders(orders)
    } catch (err) {
      setOrders([])
      setError(err)
      throw err
    }
  }

  const getPlasticsPrice = async () => {
    try {
      const price = await OrdersApi.getPlasticsPrice();
      setPlasticsPrice(price)
    } catch (err) {
      setError(err)
      throw err
    }
  }

  const updatePlasticsPrice = async (price: number): Promise<void> => {
    try {
      return await OrdersApi.updatePlasticsPrice(price);
    } catch (err) {
      setError(err)
      throw err
    }
  }

  const clearOrders = () =>{
    setOrders([])
  }

  const createOrder = async (order:CreateOrder) => {
    try {
      await OrdersApi.createOrder(order);
    } catch (err) {
      setError(err)
      throw err;
    }
  }

  return {
    orderErrorMessage,
    orders,
    plasticsPrice,
    getOrders,
    updatePlasticsPrice,
    getPlasticsPrice,
    createOrder,
    clearError,
    clearOrders
  }
}


export default useOrdersHook;