import { creatContextStore } from "./base";
import { userIsAuthorized } from "actions/userActions";
import { AppInfo } from "types";


const initialUserAuth = userIsAuthorized();
const initialState = {
  Authenticated: initialUserAuth,
  Authenticating: false,
};

const [Provider, useState, useSetState, useMergeState] =
  creatContextStore<AppInfo>(initialState);

export {
  Provider as AppContextProvider,
  useState as useAppState,
  useSetState as useSetAppState,
  useMergeState as useMergeAppState,
};
