import { ReactElement, SetStateAction, useEffect, useState } from "react";
import Autocomplete from 'react-autocomplete';
import styles from "./styles.module.scss";
import {Client } from "types";
import { BsSearch } from "@react-icons/all-files/bs/BsSearch";

interface ClientAutocompleteParams {
    updateSelectedClient: (client:Client ) => void
    clientList:Client[]
    setValue?: (value: string) => void;
    value?: string
    disabled?: boolean
}

function ClientAutocomplete({updateSelectedClient, clientList, setValue, value, disabled}: ClientAutocompleteParams ): ReactElement {

  const clientNames = clientList.map(client => {
    return {label: client.clientName}
  })


  const handleSelect = (clientName: string) => {
    const client = clientList.find((client) => client.clientName == clientName);
    if (setValue) setValue(clientName)
    if(client){
      updateSelectedClient(client);
      
    }
  }
  
  return (
    <div className={styles.clientDropdownSection}>
      <div className={styles.icon}>
        <BsSearch/>
      </div>
    <Autocomplete
      wrapperProps={{style: { zIndex: 999 }}}
      getItemValue={(item) => item.label}
      items={clientNames || []}
      inputProps={{
        style: {
          opacity: '100', 
          width: '300px',
          height: '30px',
          textAlign: 'center'
        },
        disabled:disabled,
        placeholder:'Select a Client',
      }}
      
      renderItem={(item, isHighlighted) => {
        return <div style={{ background: isHighlighted ? 'lightgray' : 'white',   opacity: '100', 
        height: '30px', lineHeight: '30px'}}>
          {item.label}
        </div>
      }}
      
      value={value}
      onChange={(e) => {if (setValue)setValue(e.target.value)}}
      onSelect={handleSelect}
    />
    </div>
  );
};

export default ClientAutocomplete;

